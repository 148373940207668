import { useLocality } from 'api'
import { useAuth } from 'hooks'
import { Election, ElectionType } from 'models'
import { useTranslation } from 'react-i18next'

type InstitutionNameProps = {
    election?: Election
}

function InstitutionName({ election }: InstitutionNameProps) {
    const { t } = useTranslation('telling-station')
    const { auth } = useAuth()

    const { data: locality } = useLocality(election?.region?.locality || '', !!election && !!election?.region?.locality)

    const getInstitutionDetails = () => {
        const localityName = locality?.name || auth?.homeLocality?.name
        const localityCode = locality?.code || auth?.homeLocality?.code
        const regionLocalUnit = election?.region?.localUnit
        const spiritualAssemblyType = t('common:spiritual_assembly_of_bahai_of')

        switch (election?.type) {
            case ElectionType.RBC_ELECTION:
                return {
                    type: t('common:national_spiritual_assembly_of_the'),
                    name: t('common:bahais_of_the_united_states')
                }
            case ElectionType.LOCAL_DELEGATE_ELECTION:
                return {
                    type: spiritualAssemblyType,
                    name: `${localityName} (${localityCode}) - ${regionLocalUnit}`
                }
            case ElectionType.RIDVAN_ELECTION:
            case ElectionType.BY_ELECTION:
                return {
                    type: spiritualAssemblyType,
                    name: `${localityName} (${localityCode})`
                }
            case ElectionType.NATIONAL_DELEGATE_ELECTION:
                return {
                    type: spiritualAssemblyType,
                    name: `${election?.hostingLocality.name} (${election?.hostingLocality.code})`
                }
            default:
                return { type: '', name: '' }
        }
    }

    const { type: institutionType, name: institutionName } = getInstitutionDetails()

    return (
        <>
            {institutionType} <b>{institutionName}</b>
        </>
    )
}

export default InstitutionName