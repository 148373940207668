export const queryKeysWithoutGeneralPreloader = [
    'election-participant',
    'election-candidates',
    'election-period-participant',
    'election-ballot-candidates',
    'report-status',
    'online-voters-with-background-refetch',
    'instructions',
    'online-voting',
    'elections-summary-chunk'
]