import { ColumnsType } from 'antd/es/table'
import IconWrapper from 'components/atoms/IconWrapper'
import TableHeaderCell from 'components/atoms/TableHeaderCell'
import ElectionBadge from 'components/molecules/ElectionBadge'
import ElectionPushResultNote from 'components/molecules/ElectionPushResultNote'
import StatisticCounter from 'components/molecules/StatisticCounter'
import Table from 'components/molecules/Table'
import { useAbility, useElectionTypeMap } from 'hooks'
import { ReactComponent as ArrowDownload } from 'icons/arrow-download.svg'
import {
    ElectionPushResult,
    ElectionStatusType,
    ElectionType,
    Locality,
    SubElectionViewModel,
    SubElectionViewWording
} from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SubElectionBallotingProgress from '../SubElectionBallotingProgress'
import SubElectionBallotsDetails from '../SubElectionBallotsDetails'
import SubElectionOnlineVotingStatus from '../SubElectionOnlineVotingStatus'
import SubElectionReports from '../SubElectionReports'
import SubElectionResultConfirm from '../SubElectionResultConfirm'
import SubElectionTellers from '../SubElectionTellers'
import SubElectionVotingStatistic from '../SubElectionVotingStatistic'
import style from './index.module.scss'

type SubElectionsListProps = {
    elections: SubElectionViewModel[]
    electionType: ElectionType
    tableWording: SubElectionViewWording
    allElectionsIds: string[]
    statistic: { inProgress: number, confirmed: number }
    downloadReport: () => void
    electionsStatus: ElectionStatusType
    electionPeriodBallotingYear?: number
    locality?: Locality
    pushResult?: ElectionPushResult
    canDownloadReport?: boolean
}

function SubElectionsList({
    electionsStatus,
    electionType,
    elections,
    electionPeriodBallotingYear,
    tableWording,
    allElectionsIds,
    statistic,
    pushResult,
    locality,
    downloadReport,
    canDownloadReport = false
}: SubElectionsListProps) {
    const { t } = useTranslation('election')
    const { toString } = useElectionTypeMap()
    const { ability } = useAbility()

    const columns: ColumnsType<SubElectionViewModel> = useMemo(() => [
        {
            title: <TableHeaderCell>{tableWording.firstColumnTitle}</TableHeaderCell>,
            render: (election: SubElectionViewModel) => election.status === ElectionStatusType.NOT_STARTED
                ? election.name
                : <Link
                    to={`/election-board/${election.id}`} state={{ backButtonTitle: t('back_to_list') }}>
                    {election.name}
                </Link>,
            width: 140,
            key: 'name'
        },
        {
            title: <TableHeaderCell>{tableWording.secondColumnTitle}</TableHeaderCell>,
            render: (election: SubElectionViewModel) => election.numberOfVacancies,
            width: 100,
            key: 'numberOfVacancies'
        },
        {
            title: <TableHeaderCell>{t('tellers')}</TableHeaderCell>,
            render: (election: SubElectionViewModel) => <SubElectionTellers
                allElectionsIds={allElectionsIds}
                election={election}
            />,
            width: 240
        },
        {
            title: <TableHeaderCell>{t('participated_eligible_voters')}</TableHeaderCell>,
            render: (election: SubElectionViewModel) => <SubElectionVotingStatistic
                allElectionsIds={allElectionsIds}
                electionId={election.id.toString()}
            />,
            width: 170
        },
        {
            title: <TableHeaderCell>{t('online_voting_status')}</TableHeaderCell>,
            render: (election: SubElectionViewModel) => <SubElectionOnlineVotingStatus
                allElectionsIds={allElectionsIds}
                electionId={election.id.toString()}
            />,
            width: 130
        },
        {
            title: <TableHeaderCell>
                <div className={style.progressWrapper}>
                    <div className={style.ballotingProgress}>
                        {t('chief_teller_s_tasks')}
                    </div>
                    <div className={style.progressSteps}>
                        <div className={style.progressStep}>
                            {t('common:telling_process')}
                        </div>
                        <div className={style.progressStep}>
                            {t('common:merging_results')}
                        </div>
                        <div className={style.progressStep}>
                            {t('common:reporting')}
                        </div>
                    </div>
                </div>
            </TableHeaderCell>,
            render: (election: SubElectionViewModel) => !!elections
                ? (<div className={style.progressBarWrapper}>
                    <SubElectionBallotingProgress
                        allElectionsIds={allElectionsIds}
                        election={election}
                        electionType={electionType}
                    />
                </div>)
                : null,
            width: 210
        },
        {
            title: <TableHeaderCell>{t('common:details')}</TableHeaderCell>,
            render: (election: SubElectionViewModel) => (
                <div className={style.detailsWrapper}>
                    <SubElectionBallotsDetails
                        allElectionsIds={allElectionsIds}
                        electionId={election.id.toString()} />
                </div>),
            width: 72
        },
        {
            title: <TableHeaderCell>{t('common:action')}</TableHeaderCell>,
            render: (election: SubElectionViewModel) => <SubElectionResultConfirm
                allElectionsIds={allElectionsIds}
                electionId={election.id.toString()} />,
            width: 171
        },
        {
            title: <TableHeaderCell>{t('common:reports')}</TableHeaderCell>,
            render: (election: SubElectionViewModel) => <SubElectionReports
                allElectionsIds={allElectionsIds}
                electionId={election.id.toString()} />,
            width: 80
        }
    ], [allElectionsIds, elections, t])

    if (!elections) return null

    return <>

        {!!pushResult && <div className={style.pushResult}>
            <ElectionPushResultNote pushResult={pushResult} />
        </div>}
        <Table
            className={style.table}
            columns={columns}
            dataSource={elections}
            pagination={false}
            stickyOffset="calc(var(--header-height) + var(--page-header-height))"
            headerDividerVisible={true}
            rowKey="id"
            caption={
                <div className={style.caption}>
                    <div className={style.electionInfo}>
                        {t('year_b_e_election',
                            {
                                year: electionPeriodBallotingYear,
                                election: toString(electionType)
                            })}
                        {ability?.can('view', 'LocalityName') && !!locality
                            && ` (${locality.name}, ${locality.code})`
                        }
                        <ElectionBadge
                            status={electionsStatus}
                        />
                    </div>
                    <div className={style.statistic}>
                        {(!!pushResult || canDownloadReport) &&
                            <button
                                className={`btn-main-tertiary-md ${style.download}`}
                                onClick={downloadReport}>
                                <IconWrapper>
                                    <ArrowDownload />
                                </IconWrapper>
                                {tableWording.reportName}
                            </button>
                        }
                        <StatisticCounter
                            value={elections?.length || 0}
                            title={tableWording.totalCountName} />
                        <StatisticCounter value={
                            (elections?.length || 0)
                            - statistic.inProgress - statistic.confirmed} title={t('not_started')} />
                        <StatisticCounter value={statistic.inProgress} title={t('in_progress')} />
                        <StatisticCounter value={statistic.confirmed} title={t('common:confirmed')} />
                    </div>
                </div>
            } />
    </>
}

export default SubElectionsList