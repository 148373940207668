import Collapse from 'components/atoms/Collapse'
import { ElectionType, ReportFormat, ReportType } from 'models'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ReportGroup } from '../../model'
import { ReportFilter } from '../../model/report-filter'
import ReportGroupItems from '../ReportGroupItems'
import style from './index.module.scss'

function Reports() {
    const { t } = useTranslation('administration')

    const reports: ReportGroup[] = useMemo((): ReportGroup[] => [
        {
            label: t('lsa_elections'),
            key: 'lsa_elections',
            items: [{
                type: ReportType.RidvanConsolidatedReport,
                format: ReportFormat.XLSX,
                label: t('election_results'),
                key: 'ridvan',
                filters: [],
                electionType: ElectionType.RIDVAN_ELECTION
            },
            {
                type: ReportType.LocalDelegateElections,
                format: ReportFormat.XLSX,
                label: t('voting_statistics'),
                key: 'lde',
                filters: []
            },
            {
                type: ReportType.RejectedUnityWebPushReasonReport,
                format: ReportFormat.XLSX,
                label: t('reasons_of_rejected_unityweb_response'),
                key: 'roruwr_lsa',
                filters: [ReportFilter.ElectionType]
            },
            {
                type: ReportType.InvalidBallotAndVoteoteReasonsReport,
                format: ReportFormat.XLSX,
                label: t('reasons_of_invalid_ballots_n_votes'),
                key: 'roibv_lsa',
                filters: [ReportFilter.ElectionType]
            },
            {
                type: ReportType.TellingStationCommentsReport,
                format: ReportFormat.XLSX,
                label: t('comments_regarding_telling_process_consolidated'),
                key: 'tsc_lsa',
                filters: [ReportFilter.ElectionType]
            },
            {
                type: ReportType.VoterPartisipationDetailsReport,
                format: ReportFormat.XLSX,
                label: t('voter_participation_details'),
                key: 'vpd_lsa',
                filters: [ReportFilter.ElectionType]
            }
            // ,
            // {
            //     type: ReportType.VoterPartisipationByAgeCategories,
            //     format: ReportFormat.XLSX,
            //     label: t('voter_participation_by_age_categories_consolidated'),
            //     key: 'vpbecc_lsa',
            //     electionTypesInFilter: [ElectionType.RIDVAN_ELECTION, ElectionType.LOCAL_DELEGATE_ELECTION],
            //     filters: [ReportFilter.ElectionType]
            // }
            ]
        },
        {
            label: t('national_delegate_elections'),
            key: 'nde',
            items: [{
                type: ReportType.NationalDelegateElectionResultsReport,
                format: ReportFormat.XLSX,
                label: t('election_results'),
                key: 'nde_election_results',
                filters: [],
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION
            },
            {
                type: ReportType.RejectedUnityWebPushReasonReport,
                format: ReportFormat.XLSX,
                label: t('reasons_of_rejected_unityweb_response'),
                key: 'roruwr_nde',
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION,
                filters: []
            },
            {
                type: ReportType.BallotingStatisticsReport,
                format: ReportFormat.XLSX,
                label: t('balloting_statistics'),
                key: 'bs_nde',
                includeSubUnits: false,
                filters: []
            },
            {
                type: ReportType.BallotingStatisticsReport,
                format: ReportFormat.XLSX,
                label: t('balloting_statistics_by_sub_units'),
                key: 'bsbysu_nde',
                includeSubUnits: true,
                filters: []
            },
            {
                type: ReportType.InvalidBallotAndVoteoteReasonsReport,
                format: ReportFormat.XLSX,
                label: t('reasons_of_invalid_ballots_n_votes'),
                key: 'roibv_nde',
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION,
                filters: []
            },
            {
                type: ReportType.TellingStationCommentsReport,
                format: ReportFormat.XLSX,
                label: t('comments_regarding_telling_process_consolidated'),
                key: 'tsc_nde',
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION,
                filters: []
            },
            {
                type: ReportType.VoterPartisipationDetailsReport,
                format: ReportFormat.XLSX,
                label: t('voter_participation_details'),
                key: 'vpd_nde',
                filters: [],
                electionType: ElectionType.NATIONAL_DELEGATE_ELECTION
            }
            // ,
            // {
            //     type: ReportType.VoterPartisipationByAgeCategories,
            //     format: ReportFormat.XLSX,
            //     label: t('voter_participation_by_age_categories_consolidated'),
            //     key: 'vpbecc_nde',
            //     filters: [],
            //     electionType: ElectionType.NATIONAL_DELEGATE_ELECTION
            // }
        ]
        },
        {
            label: t('regional_bahai_councils'),
            key: 'rbc',
            items: [
                {
                    type: ReportType.TellingStationCommentsReport,
                    format: ReportFormat.XLSX,
                    label: t('comments_regarding_telling_process_consolidated'),
                    key: 'tsc_rbc',
                    electionType: ElectionType.RBC_ELECTION,
                    filters: []
                }]
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ], [])

    return (
        <div className={style.reportsWrapper}>
            <Collapse
                className="obs-collapse"
                items={reports.map(({ key, label, items }) => ({
                    key,
                    label,
                    children: <ReportGroupItems items={items} />
                }))}
                defaultActiveKey={['lsa_elections']} />
        </div>
    )
}

export default Reports