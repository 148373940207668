import { useElection, useElectionSummary } from 'api'
import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import { queryClient } from 'config/query-client'
import { electionResults } from 'features/electionResults'
import { useAuth, useBackToDashboardLine, useConfirmPopup, useHandleEntityLoadingError, useInfoPopup } from 'hooks'
import { ElectionType } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSignElectionResult } from '../../api/mutations/useSignElectionResult'
import ElectionResult from '../ElectionResult'
import style from './index.module.scss'

function MainReport() {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const { electionId } = useParams<{ electionId: string }>()
    const { infoPortal, showInfo } = useInfoPopup()
    const { showConfirm, confirmPortal } = useConfirmPopup()
    const { setBackToDashboardLine } = useBackToDashboardLine()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const { mutateAsync: signElectionResultMutation } = useSignElectionResult(electionId || auth?.electionId!)
    const { mutateAsync: skipBreakingTie } = electionResults.api.mutations.useSkipBreakingTie()
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
    const [valid, setValid] = useState<boolean>(true)
    const { data: election, error } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: electionSummary, isError } =
        useElectionSummary(electionId || auth!.electionId!,
            (!!electionId || !!auth?.electionId) && !!election)

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine
                sticky
                fixedWidth
                backButtonTitle={location.state?.backButtonTitle || t('back_to_reports')}
                onBackToDashboardBtnClick={() => {
                    const idToUse = electionId || auth?.electionId
                    navigate(`/reports/${idToUse}`, { replace: true })
                }}>
                <div className={style.buttonsWrapper}>
                    <div>
                        <button className={`btn-main-primary-md ${style.signButtonWidth}`}
                            onClick={handleButtonClick}>
                            {t('sign_and_save')}
                        </button>
                    </div>
                </div>
            </BackToDashboardLine >
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckboxChecked])

    useEffect(() => {
        if (isError) {
            navigate('/page-not-found', { replace: true })

            return
        }
        if (!!electionSummary && !electionSummary.mergedBy) {
            navigate('/page-not-found', { replace: true })

            return
        }
        if (!!electionSummary?.signedBy) {
            navigate('/page-not-found', { replace: true })

            return
        }
        if (!!electionSummary?.tiePresent
            && !electionSummary.breakingTieSkipped
            && election?.type !== ElectionType.RBC_ELECTION) {
            navigate('/page-not-found', { replace: true })
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [electionSummary, isError])

    const handleCheckboxChange = (checked: boolean) => {
        setIsCheckboxChecked(checked)
        setValid(true)
    }

    const handleButtonClick = () => {
        if (isCheckboxChecked) {
            showSaveReportPopUp()
        } else {
            showNoSaveReportPopUp()
        }
    }

    const showSaveReportPopUp = () => {
        showConfirm({
            title: <>{`${t('sign_save_seport')}?`}</>,
            text:
                <>
                    <div className={style.content}>{t('you_are_about_to_save_the_report')}</div>
                    <div className={style.contentBold}>
                        {t('please_note_this_is_an_irreversible_action_saving_the_report')}
                    </div>
                </>,
            okText: <>{t('cancel')}</>,
            cancelText: <>{t('Sign & Save Report')}</>,
            onOk: async () => { },
            onCancel: async () => {
                await signElectionResultMutation()
                if (electionSummary?.tiePresent && election?.type === ElectionType.RBC_ELECTION) {
                    await skipBreakingTie(electionId || auth?.electionId?.toString() || '')
                }
                await queryClient.removeQueries(['election-summary', electionId])
                navigate('/feedback')
            }
        })
    }

    const showNoSaveReportPopUp = () => {
        showInfo({
            title: t('telling-station:no_signed_report'),
            text: t('to_sign_the_report_please_check_the_box_near_chief_teller_name'),
            onOk: async () => {
                setValid(false)
            }
        })
    }

    return (
        <>
            {infoPortal}
            {confirmPortal}
            <ElectionResult onCheckboxChange={handleCheckboxChange} isValid={valid} />
        </>
    )
}

export default MainReport