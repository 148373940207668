
import { useTieDesignatedCandidates } from 'api'
import { useStopOnlineVoting } from 'api/mutations'
import { Can } from 'components/atoms/Can'
import Note from 'components/molecules/Note'
import { useAbility, useAuth, useConfirmPopup, usePopup, useRevoteRoundTieCandidates } from 'hooks'
import { Election, ElectionResultSummary, ElectionStatusType, ElectionType } from 'models'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { formatNames, isElectionFrozen } from 'utils'
import { useSkipBreakingTie } from '../../api/mutations'
import {
    useBreakTheTieFlowPopup,
    useCancelLastTieRoundResultPopup,
    useDesignateElecteePopup,
    useMergeResultsData,
    useRevotePopup,
    useSelectTypeOfElectionFlow
} from '../../hooks'
import TieRoundBlock from '../TieRoundBlock'
import style from './index.module.scss'

type TieInfoBlockProps = {
    election?: Election
    summary?: ElectionResultSummary
    isError?: boolean
    comment?: string
}

function TieInfoBlock({
    summary,
    election
}: TieInfoBlockProps) {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const { ability } = useAbility()
    const { electionId } = useParams()
    const navigate = useNavigate()
    const { popupPortal, show, hide } = usePopup()
    const { confirmPortal, showConfirm, hide: closePopup } = useConfirmPopup()
    const { cancelLastTieRoundResultPopup } = useCancelLastTieRoundResultPopup(showConfirm, closePopup)
    const { showBreakTheTieFlowPopup } = useBreakTheTieFlowPopup(show, hide)
    const { showDesignateElecteePopup } = useDesignateElecteePopup(show, hide)
    const { showRevotePopup } = useRevotePopup(show, hide)
    const { mutateAsync: stopOnlineVotingMutation } = useStopOnlineVoting()
    const { mutateAsync: skipBreakingTie } = useSkipBreakingTie()
    const { mergedResultsData } = useMergeResultsData()
    const { showSelectElectionTypeFlowPopup } = useSelectTypeOfElectionFlow(show, hide)
    const { data: tieDesignatedCandidates } =
        useTieDesignatedCandidates(election?.id.toString() || '', isElectionFrozen(election?.status),
            !!election)

    const pleaseProceedToReport = t('please_click_proceed_to_reporting')
    const electedViaReportMessage = t('elected_via_revote_using_obs')

    const showChangeTieResultsButton = mergedResultsData.isTieResolved &&
        (tieDesignatedCandidates?.length ?? 0) > 0 && !summary?.signedBy

    const isRbcElection = election?.type === ElectionType.RBC_ELECTION

    const showBreakTieButtons
        = !mergedResultsData.isTieResolved
        && !summary?.breakingTieSkipped
        && !isRbcElection

    const isNationalDelegateElection = election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION


    const cannotViewChiefTellerActions = !ability!.can('view-only', 'ChiefTellerActions')
    const isNotSigned = !summary?.signedBy
    const hasSubUnitsAndTieSkipped = election?.hasSubUnits && summary?.breakingTieSkipped

    const shouldShowMessage =
        (isRbcElection && cannotViewChiefTellerActions && isNotSigned) ||
        (isNotSigned && hasSubUnitsAndTieSkipped && cannotViewChiefTellerActions)

    const onBreakTheTieClick = () => {
        showBreakTheTieFlowPopup(
            () => showDesignateElecteePopup(),
            () => showRevotePopup())
    }

    const onDesignateElecteeClick = () => {
        showDesignateElecteePopup()
    }

    const { formattedNames: revoteRoundTieCandidates } =
        useRevoteRoundTieCandidates(mergedResultsData.electionResultTie?.resolution.candidates || []
            , mergedResultsData.electionResultTie?.candidates || [], tieDesignatedCandidates || [])


    const electees = () => {
        if (tieDesignatedCandidates?.length === 0) {
            return null
        } else {
            const designatesTieCandidates =
                formatNames(tieDesignatedCandidates?.map(candidate => candidate.name
                    || `[${t('deactivated_member')}]`) || [], t('and'))

            return (
                <>
                    <span className={style.nameFont}>
                        {`${designatesTieCandidates} `}
                    </span>
                    <span className={style.textFont}>{`${t('designated_as_electee')}.`}</span>
                </>
            )
        }
    }

    const endOnlineVotingPopup = () => {
        showConfirm({
            stayOpenedOnCancel: true,
            title: <>{`${t('end_online_voting')}?`}</>,
            text:
                <>
                    <div>
                        {t('you_are_about_to_end_online_voting_to_start_telling_process')}
                        <div style={{ paddingTop: 'var(--default-space' }}>
                            <b>{t('please_note_this_is_an_irreversible_action')}</b>
                        </div>
                    </div>
                </>,
            okText: <>{t('common:cancel')}</>,
            cancelText: <>{t('end_online_voting')}</>,
            onCancel: async (e) => {
                e.preventDefault()
                const electionId = mergedResultsData.lastRound?.id.toString() || ''
                await stopOnlineVotingMutation(electionId)
                await showSelectElectionTypeFlowPopup(electionId)
                closePopup()
            },
            onOk: async () => { },
            onClose: () => { }
        })
    }

    const skipBreakTheTiePopup = () => {
        showConfirm({
            stayOpenedOnCancel: true,
            title: <>{`${t('skip_break_the_tie')}?`}</>,
            text:
                <>
                    <div>
                        {t('you_are_about_to_skip_break_the_tie_process')}
                        <div style={{ paddingTop: 'var(--default-space' }}>
                            <b>{t('please_note_this_is_an_irreversible_action')}</b>
                        </div>
                    </div>
                </>,
            okText: <>{t('common:cancel')}</>,
            cancelText: <>{t('skip_break_the_tie')}</>,
            onCancel: async (e) => {
                e.preventDefault()
                await skipBreakingTie(electionId || auth?.electionId?.toString() || '')
                navigate(`/report/${electionId || auth?.electionId?.toString() || ''}`)
                closePopup()
            },
            onOk: async () => { },
            onClose: () => { }
        })
    }

    const clearAllVotesPopup = () => {
        cancelLastTieRoundResultPopup(false)
    }

    return (
        <>
            {confirmPortal}
            {popupPortal}
            {mergedResultsData.lastRound?.status !== ElectionStatusType.IN_PROGRESS
                && <Note mode={mergedResultsData.isTieResolved ? 'success' : 'warning'} icon={'regular'}>
                    <div className={style.infoWrapper}>
                        {mergedResultsData.isTieResolved
                            ? (
                                <div className={`${style.textWrapper} ${style.successTextColor}`}>
                                    {t('tie_resolved')} <br />
                                    <>
                                        <>
                                            {revoteRoundTieCandidates.length > 0
                                                &&
                                                <span className={style.textFont}>
                                                    <b>{revoteRoundTieCandidates}</b>
                                                    {` ${electedViaReportMessage}`}
                                                    {isNotSigned
                                                        &&
                                                        (tieDesignatedCandidates?.length ?? 0) === 0 &&
                                                        ` ${pleaseProceedToReport}.`}<br />
                                                </span>
                                            }
                                        </>
                                        {(tieDesignatedCandidates?.length ?? 0) > 0
                                            &&
                                            <>
                                                {electees()}
                                                <span className={style.textFont}>
                                                    {mergedResultsData.electionResultTie?.resolution.comment
                                                        &&
                                                        <b>{` ${t('common:comment')}:`}</b>
                                                    }
                                                    {
                                                        // eslint-disable-next-line max-len
                                                        ` ${mergedResultsData.electionResultTie?.resolution.comment || ''}`}<br />
                                                </span>
                                                <Can I="view" a="InfoWording">
                                                    {(tieDesignatedCandidates?.length ?? 0) > 0 && isNotSigned
                                                        && <span className={style.textFont}>
                                                            {
                                                                // eslint-disable-next-line max-len
                                                                `${t('if_there_is_an_error_you_may_use_the_change_tie_results')}
                                                                ${pleaseProceedToReport}.`

                                                            }
                                                        </span>
                                                    }
                                                </Can>
                                            </>
                                        }
                                    </>

                                </div>
                            )
                            :
                            (
                                <div className={style.textWrapper}>
                                    {t('election_results_included_ties')} <br />
                                    {!summary?.breakingTieSkipped && !isRbcElection
                                        &&
                                        <span className={style.textFont}>
                                            {isNationalDelegateElection && election?.hasSubUnits
                                                ? t('if_the_minority_rule_applies_please_proceed_to_break_the_tie')
                                                : t('please_proceed_with_the_break_the_tie_process')}<br />
                                        </span>
                                    }
                                    {shouldShowMessage
                                        &&
                                        <>
                                            <span className={style.textFont}>
                                                {
                                                    `${pleaseProceedToReport}. `
                                                }
                                            </span>
                                            <br />
                                        </>
                                    }
                                    {revoteRoundTieCandidates.length > 0
                                        &&
                                        <span className={style.textFont}>
                                            <b>{revoteRoundTieCandidates}</b>
                                            {` ${electedViaReportMessage}`}<br />
                                        </span>
                                    }
                                    {(tieDesignatedCandidates?.length ?? 0) > 0
                                        && <>
                                            {electees()}
                                            <span className={style.textFont}>
                                                {mergedResultsData.electionResultTie?.resolution.comment &&
                                                    <b>{` ${t('common:comment')}:`}</b>}
                                                {` ${mergedResultsData.electionResultTie?.resolution.comment || ''}`
                                                }
                                            </span>
                                        </>
                                    }
                                </div>
                            )
                        }
                        <div className={style.action}>
                            {showChangeTieResultsButton
                                && (
                                    <Can I="view" a="ChangeTieResultsButton">
                                        <button
                                            className="btn-main-secondary-md"
                                            onClick={onDesignateElecteeClick}
                                        >
                                            {t('change_tie_results')}
                                        </button>
                                    </Can>
                                )}
                            {showBreakTieButtons
                                && (
                                    <div className={style.buttonsWrapper}>
                                        {isNationalDelegateElection && election?.hasSubUnits
                                            && (
                                                <button
                                                    className="btn-main-secondary-md"
                                                    onClick={skipBreakTheTiePopup}
                                                >
                                                    {t('skip_break_the_tie')}
                                                </button>
                                            )}
                                        <button
                                            className="btn-main-primary-md"
                                            onClick={isNationalDelegateElection && election?.hasSubUnits
                                                ? onDesignateElecteeClick : onBreakTheTieClick}
                                        >
                                            {t('break_the_tie')}
                                        </button>
                                    </div>
                                )}
                        </div>
                    </div>
                </Note >
            }
            {mergedResultsData.lastRound?.status === ElectionStatusType.IN_PROGRESS
                && <TieRoundBlock
                    showSelectElectionTypeFlowPopup={showSelectElectionTypeFlowPopup}
                    clearAllVotesPopup={clearAllVotesPopup}
                    endOnlineVotingPopup={endOnlineVotingPopup}
                />
            }
        </>
    )
}

export default TieInfoBlock