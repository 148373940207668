import { useElection, useElectionResultsTie, useElectionSummary, useTieDesignatedCandidates } from 'api'
import ContentWrapper from 'components/atoms/ContentWrapper'
import { reporting } from 'features/reporting'
import { useHandleEntityLoadingError, useRevoteRoundTieCandidates } from 'hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { isElectionFrozen } from 'utils'
import ConsolidatedBallotEntry from '../../../components/ConsolidatedBallotEntry'
import Comment from '../components/Comment'
import ConfirmResultsHeader from '../components/ConfirmResultsHeader'
import ResultsTable from '../components/ResultsTable'
import CommentInfoProvider from '../provider/CommentInfoProvider'
import ConfirmationProvider from '../provider/ConfirmationProvider'

function ConfirmResults() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { electionId } = useParams()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const { data: election, error } = useElection(electionId!, !!electionId)
    const { data: summary, isError: noSummary } = useElectionSummary(electionId!, !!electionId && !!election)
    const [canConfirmResults, setCanConfirmResults] = useState(false)
    const { data: electionResultTie } = useElectionResultsTie(electionId!, !!electionId)
    const { data: tieDesignatedCandidates } = useTieDesignatedCandidates(election?.id.toString() || '',
        isElectionFrozen(election?.status), !!election)
    const { formattedNames: revoteRoundTieCandidates } =
        useRevoteRoundTieCandidates(electionResultTie?.resolution.candidates || []
            , electionResultTie?.candidates || [], tieDesignatedCandidates || [])

    const infoText = summary?.breakingTieSkipped ? t('teller:election_results_included_ties') : t('teller:tie_resolved')
    const infoMode = summary?.breakingTieSkipped ? 'warning' : 'success'

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (noSummary) {
            navigate('/page-not-found', { replace: true })

            return
        }
        if (!!election && !canConfirmResults && summary
            && (!summary.signedBy || !!summary.confirmedBy)) {
            navigate('/page-not-found', { replace: true })
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [election, summary, noSummary])

    useEffect(() => {
        if (!canConfirmResults && !!summary?.signedBy && !summary.confirmedBy) {
            setCanConfirmResults(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summary])

    useEffect(() => { window.scrollTo({ top: 0, left: 0 }) }, [])

    return (
        <ConfirmationProvider>
            <CommentInfoProvider>
                <ContentWrapper>
                    <ConfirmResultsHeader />
                    {(electionResultTie?.resolution.resolved || summary?.breakingTieSkipped)
                        && <div style={{ marginBottom: 'var(--double-space)' }}>
                            <reporting.components.TieResolvedInfo
                                electionId={electionId || ''}
                                summary={summary}
                                tieDesignatedCandidates={tieDesignatedCandidates}
                                revoteRoundTieCandidates={revoteRoundTieCandidates}
                                noteProps={{
                                    title: infoText,
                                    icon: 'regular',
                                    mode: infoMode
                                }} />
                        </div>}
                    <ResultsTable election={election} canShowLocalUnit />
                    <Comment viewMode={false} />
                    <ConsolidatedBallotEntry />
                </ContentWrapper>
            </CommentInfoProvider>
        </ConfirmationProvider>
    )
}

export default ConfirmResults