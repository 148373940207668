import { ColumnsType } from 'antd/es/table'
import { useCandidatesOnlineVotes, useElectionCandidates } from 'api'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Table from 'components/molecules/Table'
import { ReactComponent as MinusRounded } from 'icons/minus-rounded.svg'
import { ReactComponent as PlusRounded } from 'icons/plus-rounded.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCandidatesPhysicalVotes, useUpdateCandidatePhysicalVotes } from '../../api'
import { PhysicalBallotsFlowParticipant } from '../../model'
import style from './index.module.scss'

function TieInputPhysicalBallotsTable() {
    const { t } = useTranslation('teller')
    const { electionId } = useParams()

    const [candidates, setCandidates] = useState<PhysicalBallotsFlowParticipant[]>([])

    const { mutateAsync: updateCandidatePhysicalVotes } = useUpdateCandidatePhysicalVotes()
    const { data: electionCandidates } = useElectionCandidates(electionId || '', {}, !!electionId)
    const { data: candidatesOnlineVotes } = useCandidatesOnlineVotes(electionId || '', !!electionId)
    const { data: candidatesPhysicalVotes } = useCandidatesPhysicalVotes(electionId || '', !!electionId)

    const updateCount = async (candidateId: string, votesCount: number) => {
        await updateCandidatePhysicalVotes({ electionId: electionId!, candidateId, votesCount })
    }

    useEffect(() => {
        if (electionCandidates) {
            let allCandidates = [...electionCandidates.map(c => c as PhysicalBallotsFlowParticipant)]

            if (candidatesOnlineVotes) {
                allCandidates = allCandidates.map(candidate => ({
                    ...candidate,
                    onlineVotes: candidatesOnlineVotes.find(onlineVotes =>
                        onlineVotes.candidate === candidate.id)?.voteCount || 0
                }))
            }

            if (candidatesPhysicalVotes) {
                allCandidates = allCandidates.map(candidate => ({
                    ...candidate,
                    physicalVotes: candidatesPhysicalVotes.find(physicalVotes =>
                        physicalVotes.candidate === candidate.id)?.voteCount || 0
                }))
            }

            allCandidates.sort((a, b) => {
                if ((b.onlineVotes ?? 0) !== (a.onlineVotes ?? 0)) {
                    return (b.onlineVotes ?? 0) - (a.onlineVotes ?? 0)
                }

                return a.name.localeCompare(b.name)
            })

            setCandidates(allCandidates)
        }
    }, [electionCandidates, candidatesOnlineVotes, candidatesPhysicalVotes])



    const columns: ColumnsType<PhysicalBallotsFlowParticipant> = [
        {
            title: t('recipient'),
            width: 380,
            render: (candidate: PhysicalBallotsFlowParticipant) => (
                <div style={{ width: 370 }}>
                    <OptionalTooltip contentWrapperClassName={'ellipsis'}>
                        {candidate.name}
                    </OptionalTooltip>
                </div>
            )
        },
        {
            title: t('common:online'),
            width: 80,
            render: (candidate: PhysicalBallotsFlowParticipant) => <div style={{ width: 70 }}>
                {candidate.onlineVotes || 0}
            </div>
        },
        {
            title: t('other_methods'),
            width: 120,
            render: (candidate: PhysicalBallotsFlowParticipant) => (
                <div className={style.counterWrapper}>
                    <PlusRounded style={{ width: 18, height: 18 }}
                        onClick={() => updateCount(candidate.id, (candidate.physicalVotes || 0) + 1)} />
                    <div className={style.counter}>{candidate.physicalVotes || 0}</div>
                    <MinusRounded
                        className={!!candidate.physicalVotes ? '' : style.iconDisabledColor}
                        style={{ width: 20, height: 20 }}
                        onClick={() => {
                            if (candidate.physicalVotes && candidate.physicalVotes > 0) {
                                updateCount(candidate.id, candidate.physicalVotes - 1)
                            }
                        }} />
                </div>
            )
        }
    ]

    return (
        <Table
            obsSize="small"
            obsHeaderSize="small"
            headerDividerVisible
            stickyOffset="111px"
            dataSource={candidates}
            columns={columns}
            pagination={false}
            rootClassName={style.table}
            rowKey="id"
        />
    )
}

export default TieInputPhysicalBallotsTable