import { useElectionSummary, useTieDesignatedCandidates } from 'api'
import { Can } from 'components/atoms/Can'
import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import Preloader from 'components/molecules/Preloader'
import { useAuth, useBackToDashboardLine, useInfoPopup } from 'hooks'
import { Election, ElectionType } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { isElectionFrozen } from 'utils'
import { useUnmergeResultsLogic } from '../../../hooks'
import { useMergeResultsData } from '../../hooks'
import style from './index.module.scss'

type MergedResultsHeaderProps = {
    election?: Election
}

function MergedResultsHeader({
    election
}: MergedResultsHeaderProps) {
    const { t } = useTranslation('teller')
    const location = useLocation()
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { setBackToDashboardLine } = useBackToDashboardLine()
    const { infoPortal, showInfo } = useInfoPopup()
    const { data: summary, isError } =
        useElectionSummary(auth!.electionId!, !!auth?.electionId)
    const { data: tieDesignatedCandidates } =
        useTieDesignatedCandidates(auth?.electionId?.toString() || '',
            isElectionFrozen(election?.status), !!auth?.electionId)
    const { waitingForUnmergeResults, confirmUnmergeResults } =
        useUnmergeResultsLogic(election?.id?.toString(), summary, isError)

    const { mergedResultsData } = useMergeResultsData()

    const handleProceedToReport = (electionId: string) => {
        let url = ''
        if (electionId) {
            url = `/report/${electionId}`
        }
        navigate(url)
    }

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine sticky fixedWidth
                onBackToDashboardBtnClick={() => location.state?.backButtonTitle ?
                    navigate(-1) : navigate('/')}>
                <Can I="view" a="UnmergeResultsButtons">
                    <div className={style.buttonsWrapper}>
                        {(!mergedResultsData.isTieResolved && !summary?.signedBy && !summary?.breakingTieSkipped
                            && !mergedResultsData.isAnyBreakingTieRound && (tieDesignatedCandidates?.length ?? 0) === 0)
                            && < div >
                                <button
                                    className="btn-main-tertiary-md"
                                    onClick={() => showInfo({
                                        title: <>{t('unmerge_results')}</>,
                                        text:
                                            <>
                                                <div className={style.content}>
                                                    {t('you_are_about_to_unmerge_results')}
                                                </div>
                                                <div className={style.contentBold}>
                                                    {t('to_proceed_please_confirm')}
                                                </div>
                                            </>,
                                        onOk: () => {
                                            confirmUnmergeResults()
                                        },
                                        okText: <>{t('confirm')}</>
                                    })}>{t('unmerge_results')}
                                </button>
                            </div>}
                        {!summary?.signedBy && <div>
                            <button
                                disabled={summary?.tiePresent
                                    && !summary?.breakingTieSkipped
                                    && election?.type !== ElectionType.RBC_ELECTION}
                                onClick={() => { handleProceedToReport(auth!.electionId!) }}
                                className="btn-main-primary-md">{t('proceed_to_reporting')}
                            </button>
                        </div>}
                    </div>
                </Can>
            </BackToDashboardLine >
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setBackToDashboardLine, election, summary,
        mergedResultsData.isTieResolved, mergedResultsData.isAnyBreakingTieRound, tieDesignatedCandidates])

    return (
        <>
            {waitingForUnmergeResults && <Preloader />}
            {infoPortal}
        </>
    )
}

export default MergedResultsHeader