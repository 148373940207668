


import { useElectionsSummary, useLocalDelegateElectionPushResult, useLocality } from 'api'
import PageHeader from 'components/atoms/PageHeader'
import Tooltip from 'components/atoms/Tooltip'
import BackToDashboardBtn from 'components/molecules/BackToDashboardBtn'
import Preloader from 'components/molecules/Preloader'
import { useHandleEntityLoadingError, usePageHeader, useReportLoader } from 'hooks'
import {
    ElectionStatusType,
    ReportType,
    ReportFormat,
    SubElectionViewWording,
    SubElectionViewModel,
    RidvanElectionPeriod,
    LocalDelegateStageElection,
    AppointedTeller,
    ElectionType
} from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import SubElectionsList from '../../../../components/SubElectionsList'
import { useElectionPeriod, usePushLocalDelegateResults } from '../../api'
import { getLocalDelegateStatus } from '../../utils'

function UnitsElections() {
    const { t } = useTranslation('election')
    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const navigate = useNavigate()
    const { setPageHeader } = usePageHeader()
    const { electionPeriodId } = useParams()
    const { data: ridvanElectionPeriod, error } = useElectionPeriod(electionPeriodId!)
    const { loadReport, isReportLoading } = useReportLoader()

    const [statistic, setStatistic] = useState<{ inProgress: number, confirmed: number }>(
        { inProgress: 0, confirmed: 0 })

    const allElectionsIds = useMemo(() =>
        ridvanElectionPeriod?.localDelegateElectionStage?.elections.map(e => e.id.toString()) || [],
        [ridvanElectionPeriod]
    )

    const { data: electionsSummary } = useElectionsSummary({
        electionIds: allElectionsIds
    }, !!ridvanElectionPeriod)
    const { mutateAsync: pushLocalDelegateResults } = usePushLocalDelegateResults()
    const { data: pushResult, refetch } = useLocalDelegateElectionPushResult(electionPeriodId || '')
    const { data: locality } = useLocality(ridvanElectionPeriod?.localityCode || '', !!ridvanElectionPeriod)

    const sendResults = () => {
        if (electionPeriodId) {
            pushLocalDelegateResults({ electionPeriod: electionPeriodId })
            refetch()
        }
    }

    const canSend = useMemo(() =>
        electionsSummary &&
        electionsSummary.filter(eS => eS.electionResults.confirmedBy).length === allElectionsIds.length &&
        !pushResult?.status,
        [electionsSummary, allElectionsIds, pushResult]
    )



    const tableWording: SubElectionViewWording = {
        firstColumnTitle: t('local_unit'),
        secondColumnTitle: t('#_of_delegates'),
        reportName: t('administration:summary_report'),
        totalCountName: t('total_number_of_units')
    }
    const electionsTableData = (ridvanElectionPeriod: RidvanElectionPeriod | undefined): SubElectionViewModel[] => {
        if (!ridvanElectionPeriod || !ridvanElectionPeriod.localDelegateElectionStage) {
            return []
        }

        return ridvanElectionPeriod.localDelegateElectionStage.elections
            .map((election: LocalDelegateStageElection): SubElectionViewModel => ({
                id: election.id,
                name: election.localUnitCode,
                status: election.status,
                numberOfVacancies: election.numberOfDelegates,
                tellers: election.tellers as AppointedTeller[],
                bypassFlow: election.bypassFlow
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
    }


    const subElectionViewModels = useMemo(() =>
        ridvanElectionPeriod ? electionsTableData(ridvanElectionPeriod) : [],
        [ridvanElectionPeriod]
    )

    const electionsStatus = useMemo(() =>
        getLocalDelegateStatus(subElectionViewModels || [], pushResult),
        [subElectionViewModels, pushResult]
    )

    useEffect(() => {
        setPageHeader(
            <PageHeader
                sticky
                greeting={<BackToDashboardBtn />}>
                {!pushResult && <button className="btn-main-primary-md"
                    onClick={() => sendResults()}
                    disabled={!canSend}>
                    {canSend
                        ? <>{t('send_results_to')} {t('common:eMembership2')}</>
                        : <Tooltip title={t('election:please_confirm_the_results_for_each_unit')}>
                            {t('send_results_to')} {t('common:eMembership2')}
                        </Tooltip>}
                </button>
                }
            </PageHeader>
        )

        return () => { setPageHeader(null) }
    }, [pushResult, electionsSummary, canSend])

    useEffect(() => {
        if (ridvanElectionPeriod && !ridvanElectionPeriod.localDelegateElectionStage)
            navigate('/page-not-found', { replace: true })
        if (ridvanElectionPeriod?.localDelegateElectionStage?.elections?.length) {
            setStatistic(() => ({ confirmed: 0, inProgress: 0 }))
            ridvanElectionPeriod.localDelegateElectionStage.elections.forEach(e => {
                if (e.status === ElectionStatusType.IN_PROGRESS) {
                    setStatistic((current) => ({ ...current, inProgress: current.inProgress + 1 }))
                } else if (e.status === ElectionStatusType.COMPLETED) {
                    setStatistic((current) => ({ ...current, confirmed: current.confirmed + 1 }))
                }
            })
        }
    }, [ridvanElectionPeriod])

    useEffect(() => {
        handleEntityLoadingError(error)
    }, [error])

    const downloadSummaryReport = () => {
        loadReport({
            reportType: ReportType.LDESummaryReport,
            reportFormat: ReportFormat.PDF,
            params: {
                electionPeriod: electionPeriodId
            }
        })
    }

    return (
        <>
            {isReportLoading && <Preloader title={t('working_on_your_report')} />}
            <SubElectionsList
                electionsStatus={electionsStatus}
                electionType={ElectionType.LOCAL_DELEGATE_ELECTION}
                elections={subElectionViewModels}
                electionPeriodBallotingYear={ridvanElectionPeriod?.ballotingYear}
                tableWording={tableWording}
                allElectionsIds={allElectionsIds}
                statistic={statistic}
                pushResult={pushResult}
                locality={locality}
                downloadReport={downloadSummaryReport}
            />
        </>
    )
}

export default UnitsElections