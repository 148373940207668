import { useElection, useElectionResultsTie, useElectionSummary, useTieDesignatedCandidates } from 'api'
import ContentWrapper from 'components/atoms/ContentWrapper'
import { reporting } from 'features/reporting'
import { useHandleEntityLoadingError, useRevoteRoundTieCandidates } from 'hooks'
import { ElectionType } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { isElectionFrozen } from 'utils'
import ConsolidatedBallotEntry from '../../../components/ConsolidatedBallotEntry'
import Comment from '../components/Comment'
import ConfirmedResultsHeader from '../components/ConfirmedResultsHeader'
import ConfirmedResultsNote from '../components/ConfirmedResultsNote'
import ResultsTable from '../components/ResultsTable'
import CommentInfoProvider from '../provider/CommentInfoProvider'
import ConfirmationProvider from '../provider/ConfirmationProvider'

function ConfirmedResults() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { electionId } = useParams()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()
    const { data: election, error } = useElection(electionId!, !!electionId)
    const { data: summary, isError: noSummary } = useElectionSummary(electionId!, !!electionId && !!election)
    const { data: electionResultTie } = useElectionResultsTie(electionId!, !!electionId)
    const { data: tieDesignatedCandidates } = useTieDesignatedCandidates(election?.id.toString() || '',
        isElectionFrozen(election?.status), !!election)
    const { formattedNames: revoteRoundTieCandidates } =
        useRevoteRoundTieCandidates(electionResultTie?.resolution.candidates || []
            , electionResultTie?.candidates || [], tieDesignatedCandidates || [])

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {

        if (summary && !summary.confirmedBy) {
            navigate('/page-not-found', { replace: true })
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [summary, noSummary])

    useEffect(() => { window.scrollTo({ top: 0, left: 0 }) }, [])

    return (
        <ConfirmationProvider>
            <ContentWrapper>
                {election?.type !== ElectionType.RBC_ELECTION &&
                    <ConfirmedResultsNote />
                }
                <ConfirmedResultsHeader />
                {(electionResultTie?.resolution.resolved || summary?.breakingTieSkipped)
                    && <div style={{ marginBottom: 'var(--double-space)' }}>
                        <reporting.components.TieResolvedInfo
                            electionId={electionId || ''}
                            summary={summary}
                            tieDesignatedCandidates={tieDesignatedCandidates}
                            revoteRoundTieCandidates={revoteRoundTieCandidates}
                            noteProps={{
                                title: t('teller:tie_resolved'),
                                icon: 'regular',
                                mode: 'success'
                            }} />
                    </div>}
                <ResultsTable election={election} canShowLocalUnit />
                <CommentInfoProvider>
                    {!!summary
                        && <Comment viewMode readOnlyComment={{
                            text: summary.comment,
                            modifiedAt: summary.commentedAt,
                            modifiedBy: summary.commentedBy
                        }} />
                    }
                </CommentInfoProvider>
                <ConsolidatedBallotEntry />
            </ContentWrapper>
        </ConfirmationProvider>
    )
}

export default ConfirmedResults