import { Can } from 'components/atoms/Can'
import { Select } from 'components/atoms/Select'
import { Election, ElectionResultSummary, ElectionType } from 'models'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import style from './index.module.scss'

type ResultsTableFilterProps = {
    title: ReactNode,
    election?: Election,
    onFilterChange: (value: string) => void,
    showAllVacanciesOnly?: boolean,
    showLinkToConfirmedResults?: boolean,
    showLinkToConfirmResults?: boolean,
    isOnDashboard?: boolean
    summary?: ElectionResultSummary
}

function ResultsTableFilter({
    title,
    election,
    onFilterChange,
    showAllVacanciesOnly,
    showLinkToConfirmedResults,
    showLinkToConfirmResults,
    isOnDashboard,
    summary
}: ResultsTableFilterProps) {
    const { t } = useTranslation('teller')
    const navigate = useNavigate()
    const tieSuffix = summary?.tiePresent ? ` + ${t('tied_votes')}` : ''

    const options = [
        { value: '1', label: `${t('show_top', { count: election?.numberOfVacancies || 0 })}${tieSuffix}` },
        { value: '2', label: t('show_top', { count: 30 || 0 }) },
        { value: '3', label: t('common:all') }
    ]

    const handleChange = (value: unknown) => {
        onFilterChange(value as string)
    }

    const containsVacancies = () => {
        if (election) {
            switch (election.type) {
                case ElectionType.RIDVAN_ELECTION:
                case ElectionType.RBC_ELECTION:
                case ElectionType.BY_ELECTION:
                    return true
            }
        }

        return false
    }

    const vacantLabel = () => <div className={style.infoText}>
        {`${containsVacancies() ? t('vacancies') : t('common:delegates')}: ${election?.numberOfVacancies}`}</div>

    return (
        <div className={style.headerWrapper}>
            <div className={style.caption}>
                <div className={style.tableTitles}>
                    <div className={style.tableTitle}>
                        {title}
                    </div>
                </div>
                <div className={style.controls}>
                    {showAllVacanciesOnly
                        ? vacantLabel()
                        : <div className={style.filterWrapper}>
                            {vacantLabel()}
                            <div>
                                <Select
                                    className="small"
                                    style={{ width: summary?.tiePresent ? 230 : 160 }}
                                    defaultValue={options[0].value}
                                    options={options}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    }
                    {
                        showLinkToConfirmedResults &&
                        <button className="btn-main-secondary-md"
                            onClick={() => {
                                navigate(`/confirmed-results/${election?.id.toString() || ''}`, isOnDashboard
                                    ? undefined
                                    : { state: { backButtonTitle: t('election:back_to_election_board') } })
                            }}>
                            {t('common:view_results')}
                        </button>
                    }
                    {showLinkToConfirmResults
                        && election
                        && election.resultsSignedBy
                        && !election.resultsConfirmedBy &&
                        <Can I="confirm" a="Results">
                            <button className="btn-main-primary-md"
                                onClick={() => {
                                    navigate(`/confirm-results/${election?.id.toString() || ''}`, isOnDashboard
                                        ? undefined
                                        : { state: { backButtonTitle: t('election:back_to_election_board') } })
                                }}>
                                {t('common:confirm_results')}
                            </button>
                        </Can>
                    }
                </div>
            </div>
        </div>
    )
}

export default ResultsTableFilter