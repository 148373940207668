import { ColumnsType } from 'antd/es/table'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Table from 'components/molecules/Table'
import { CandidatesRoundStatistic, RoundStatisticWithElecteeCandidatesInfo } from 'models'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from 'utils'
import style from './index.module.scss'

type TiePhysicalBallotsTableProps = {
    onScrollToBottom?: () => void
    stickyOffset?: string
    roundStatistic?: RoundStatisticWithElecteeCandidatesInfo[]
}

function TieRoundResultsTable({
    onScrollToBottom,
    stickyOffset,
    roundStatistic
}: TiePhysicalBallotsTableProps) {
    const { t } = useTranslation('teller')

    const columns: ColumnsType<RoundStatisticWithElecteeCandidatesInfo> = [
        {
            title: `${t('common:pos')}.`,
            dataIndex: 'position',
            width: 30,
            render: (text: string) => <div style={{ width: 30 }}>{text}</div>
        },
        {
            title: t('bahai_id'),
            dataIndex: 'id',
            width: 60,
            render: (text: string) => <div style={{ width: 60 }}>{text}</div>
        },
        {
            title: t('full_name'),
            dataIndex: 'name',
            width: 310,
            render: text => (
                <div style={{ width: 300 }}>
                    <OptionalTooltip contentWrapperClassName={'ellipsis'} title={text}>
                        {text}
                    </OptionalTooltip>
                </div>
            )
        },
        {
            title: t('gender'),
            dataIndex: 'gender',
            width: 70,
            render: (_, record: CandidatesRoundStatistic) => <div style={{ width: 60 }}>
                {!!record.gender ? capitalizeFirstLetter(record.gender) : String.fromCharCode(8212)}</div>
        },
        {
            title: t('locality_code'),
            dataIndex: 'locality',
            key: 'localityCode',
            width: 70,
            render: (_, record: CandidatesRoundStatistic) => <div style={{ width: 60 }}>{record?.locality?.code}</div>
        },
        {
            title: t('race'),
            dataIndex: 'race',
            width: 185,
            render: text => (
                <div style={{ width: 180 }}>
                    <OptionalTooltip contentWrapperClassName={'ellipsis'} title={text}>
                        {text}
                    </OptionalTooltip>
                </div>
            )
        },
        {
            title: t('ethnicity'),
            dataIndex: 'ethnicity',
            width: 185,
            render: text => (
                <div style={{ width: 180 }}>
                    <OptionalTooltip contentWrapperClassName={'ellipsis'} title={text}>
                        {text}
                    </OptionalTooltip>
                </div>
            )
        },
        {
            title: t('online_votes'),
            dataIndex: 'onlineVoteCount',
            width: 80,
            render: (text: string) => <div style={{ width: 70 }}>{text}</div>
        },
        {
            title: t('other_methods_votes'),
            dataIndex: 'otherMethods',
            width: 105,
            render: (_, record: CandidatesRoundStatistic) => {

                const otherMethodsVotes = record.voteCount - record.onlineVoteCount

                return <div style={{ width: 100 }}>{otherMethodsVotes}</div>
            }
        },
        {
            title: t('total_votes'),
            dataIndex: 'voteCount',
            width: 80,
            render: (text: string) => <div style={{ width: 70 }}>{text}</div>
        }
    ]

    return (
        <Table
            onScrollToBottom={onScrollToBottom}
            obsSize="small"
            stickyOffset={stickyOffset}
            obsHeaderSize="small"
            headerDividerVisible
            dataSource={roundStatistic}
            columns={columns}
            pagination={false}
            rootClassName={style.table}
            rowKey="id"
        />
    )
}

export default TieRoundResultsTable
