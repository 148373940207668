import { Select as AntSelect } from 'antd'
import Input from 'components/atoms/Input'
import { Select } from 'components/atoms/Select'
import Tooltip from 'components/atoms/Tooltip'
import { useAllOption } from 'hooks'
import { ReactComponent as CheckboxCheckedFilled } from 'icons/checkbox-checked-filled.svg'
import { ReactComponent as CheckboxUnchecked } from 'icons/checkbox-unchecked.svg'
import { ReactComponent as Dismiss } from 'icons/dismiss.svg'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OptionalTooltip from '../OptionalTooltip'
import style from './index.module.scss'
const { Option } = AntSelect

type MultiSelectProps = React.ComponentProps<typeof Select> & {
    value: string[]
    options: { value: string, label: string }[]
    /**
    *  @defaultValue true
    */
    filterable?: boolean
    /**
   *  @defaultValue true
   */
    includeAll?: boolean,
    popupWidth?: number,
    onChange: (value: string[] | undefined) => void,
    searchCleared?: boolean,
    onSearchValueChanged?: () => void
}

const MultiSelect = ({
    value,
    options,
    filterable = true,
    includeAll = true,
    onChange,
    popupWidth,
    searchCleared,
    onSearchValueChanged,
    ...props
}: MultiSelectProps) => {

    const { t } = useTranslation()
    const [searchValue, setSearchValue] = useState('')
    const [isAllSelected, setIsAllSelected] = useState(false)
    const allOption = useAllOption()
    const filteredOptions = useMemo(() =>
        options.filter((option) =>
            option.label.toLowerCase().includes(searchValue.toLowerCase())
        ),
        [options, searchValue]
    )

    useEffect(() => {
        if (searchCleared) {
            setSearchValue('')
        }
    }, [searchCleared])

    const getRenderOptions = () => {
        const filtered = filteredOptions.map(o => ({ value: o.value, label: o.label }))

        if (includeAll && filtered.length === options.length) {
            return [allOption].concat(filtered)
        }

        return filtered
    }

    const handleMultiSelectChange = (selectedValues: string[]): void => {

        const getSelectedIncludingAll = () => {
            if (selectedValues.includes(allOption.value)) {
                if (!isAllSelected) {
                    setIsAllSelected(true)

                    return [allOption.value].concat(options.map(option => option.value))
                }
                if (selectedValues.length !== options.length + 1) {
                    setIsAllSelected(false)

                    return selectedValues.filter(v => v !== allOption.value)
                }
            } else {
                if (isAllSelected) {
                    setIsAllSelected(false)

                    return []
                }
                if (selectedValues.length === options.length) {
                    setIsAllSelected(true)

                    return [allOption.value].concat(options.map(option => option.value))
                }
            }

            return selectedValues
        }

        if (includeAll) {
            onChange(getSelectedIncludingAll())

            return
        }
        onChange(selectedValues)
    }

    const getOptionLabel = (val: string) => options.find(o => o.value === val)?.label || val

    const tooltipText = () => {
        const maxLength = 15


        const tooltipValues = value.filter(val => val !== allOption.value)
        if (!tooltipValues.length) {
            return null
        }

        if (tooltipValues.length <= maxLength) {
            return tooltipValues.map((val, i) => <div key={i}>{getOptionLabel(val)}</div>)
        } else {
            const result = tooltipValues.slice(0, maxLength).map((val, i) => <div key={i}>{getOptionLabel(val)}</div>)

            return <>{result}<div>{t('and_count_more', { count: tooltipValues.length - maxLength })}</div></>
        }

    }

    return (
        <Tooltip title={tooltipText()}>
            <div className={isAllSelected ? style.selectAll : ''}>
                <Select
                    {...props}
                    value={value}
                    mode="multiple"
                    allowClear={props.allowClear === true
                        ? { clearIcon: <Dismiss className="rotate-90" width="12" height="12" /> }
                        : (props.allowClear || false)}
                    onChange={(value) => handleMultiSelectChange(value as string[])}
                    notFoundContent={t('no_results')}
                    removeIcon={null}
                    showSearch={false}
                    menuItemSelectedIcon={<CheckboxCheckedFilled height="14" width="14"
                        className={style.checkedIcon} />}
                    maxTagPlaceholder={<>
                        {
                            (isAllSelected || value.includes(allOption.value))
                                ? allOption.label
                                : value.map(v => getOptionLabel(v)).join(', ')
                        }
                    </>}
                    maxTagCount={0}
                    popupMatchSelectWidth={popupWidth || true}
                    dropdownRender={(menu) => (
                        <>
                            {filterable &&
                                <Input
                                    rootClassName="small"
                                    className={`${style.filter} small`}
                                    allowClear={true}
                                    style={{ width: '100%' }}
                                    placeholder={t('type_for_search')}
                                    value={searchValue}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value)
                                        onSearchValueChanged?.()
                                    }}
                                />
                            }
                            {menu}
                        </>
                    )
                    }
                >
                    {getRenderOptions().map(option =>
                        <Option value={option.value} label={option.label} key={option.value} >
                            <div className={style.option}>
                                <CheckboxUnchecked height="14" width="14"
                                    className={`${style.uncheckedIcon} unchecked-icon`} />
                                <OptionalTooltip contentWrapperClassName="ellipsis" title={option.label}>
                                    {option.label}
                                </OptionalTooltip>
                            </div>
                        </Option>
                    )}
                </Select>
            </div>
        </Tooltip >
    )
}

export default MultiSelect
