import { useElection, useElectionSummary } from 'api'
import ContentWrapper from 'components/atoms/ContentWrapper'
import BackToDashboardLine from 'components/molecules/BackToDashboardLine'
import { tellingStation } from 'features/tellingStation'
import { useAuth, useBackToDashboardLine, useHandleEntityLoadingError } from 'hooks'
import { ElectionType } from 'models'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import style from './index.module.scss'

const VIEW_MODE = true

function StationReport() {
    const { t } = useTranslation('teller')
    const { auth } = useAuth()
    const { electionId } = useParams<{ electionId: string }>()
    const { setBackToDashboardLine } = useBackToDashboardLine()
    const navigate = useNavigate()
    const location = useLocation()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()

    const { data: electionSummary, error } =
        useElectionSummary(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)

    const { data: election } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)

    useEffect(() => {
        if (error) {
            handleEntityLoadingError(error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error])

    useEffect(() => {
        if (!!electionSummary && !electionSummary.mergedBy) {
            navigate('/page-not-found', { replace: true })

            return
        }
        if (!!electionSummary?.tiePresent
            && !electionSummary?.breakingTieSkipped
            && election?.type !== ElectionType.RBC_ELECTION
        ) {
            navigate('/page-not-found', { replace: true })
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [electionSummary])

    useEffect(() => {
        setBackToDashboardLine(
            <BackToDashboardLine
                backButtonTitle={location.state?.backButtonTitle || t('back_to_reports')}
                sticky
                fixedWidth
                onBackToDashboardBtnClick={() => { navigate(-1) }}>
                <div className={style.statusesWrapper}>
                    <span className={style.statuses}>{t('signed')}</span>
                </div>
            </BackToDashboardLine>
        )

        return () => { setBackToDashboardLine(null) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ContentWrapper>
            <tellingStation.components.StatisticalSignature viewMode={VIEW_MODE} />
        </ContentWrapper>
    )
}

export default StationReport