import { Form } from 'antd'
import { useElection, useStation, useStationVerifiedVoterCount } from 'api'
import Card from 'components/atoms/Card'
import Checkbox from 'components/atoms/Checkbox'
import TextArea from 'components/atoms/TextArea'
import Note from 'components/molecules/Note'
import { reporting } from 'features/reporting'
import { useAuth, useHandleEntityLoadingError, useHandleStationLogoutError, useInfoPopup } from 'hooks'
import { ReactNode, Ref, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useBallotsSummary } from '../../../../api'
import { useTellingStationInfo } from '../../../../hooks'
import {
    useSignStationReport,
    useStationTellers,
    useTellingStationSigners,
    useVoteSummary,
    useVoterSummaryReport
} from '../../api'
import style from './index.module.scss'

type CheckedStates = Record<string, boolean>

export interface StatisticalSignatureProps {
    handleButtonClick?: () => void
    viewMode?: boolean
    tutorial?: ReactNode,
    onSigned?: () => void,
    hideElectionTypeLabel?: boolean
}

const StatisticalSignature = forwardRef((props: StatisticalSignatureProps, ref: Ref<StatisticalSignatureProps>) => {
    const { viewMode } = props
    const { t } = useTranslation('telling-station')
    const { auth } = useAuth()
    const params = useParams()
    const paramsElectionId = params.electionId
    const paramsStationId = params.stationId
    const { infoPortal, showInfo } = useInfoPopup()
    const { info, setInfo } = useTellingStationInfo()
    const [form] = Form.useForm()
    const [commentStatus, setCommentStatus] = useState<'' | 'error'>('')
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false)
    const [checkedStates, setCheckedStates] = useState<CheckedStates>({})
    const [valid, setValid] = useState<boolean>(false)
    const { handleError } = useHandleStationLogoutError()
    const { handleEntityLoadingError } = useHandleEntityLoadingError()

    const { data: election, error } = useElection(paramsElectionId || auth?.electionId!,
        !!paramsElectionId || !!auth?.electionId)
    const { data: stationVerifiedVoterCount } = useStationVerifiedVoterCount(paramsElectionId || auth?.electionId!,
        paramsStationId || auth?.stationId!)
    const { data: voterSummaryReport } = useVoterSummaryReport(paramsStationId || auth?.stationId!,
        paramsElectionId || auth?.electionId!, !!paramsStationId || !!auth?.stationId)
    const { data: ballotSummary } = useBallotsSummary(paramsStationId || auth?.stationId!,
        paramsElectionId || auth?.electionId!)
    const { data: voteSummary } = useVoteSummary(paramsStationId || auth?.stationId!,
        paramsElectionId || auth?.electionId!)
    const { data: stationTellers } = useStationTellers(paramsStationId || auth?.stationId!,
        paramsElectionId || auth?.electionId!)
    const { data: station, error: stationError } = useStation({
        electionId: paramsElectionId || auth!.electionId!,
        stationId: paramsStationId || auth!.stationId!
    }, !!auth)
    const { data: tellingStationSigners } = useTellingStationSigners(paramsElectionId || auth?.electionId!,
        paramsStationId || auth?.stationId!, !!paramsStationId || !!auth?.stationId)
    const { mutateAsync: signStationReportMutation } = useSignStationReport()

    const tellingStationSignersData = viewMode ? tellingStationSigners : stationTellers

    const hasDiscrepancy = (stationVerifiedVoterCount?.totalCount ?? 0)
        !== (ballotSummary?.validBallotCount || 0) + (ballotSummary?.invalidBallotCount || 0)

    const discrepancyDetails = {
        voters: stationVerifiedVoterCount?.totalCount ?? 0,
        ballots: ((ballotSummary?.validBallotCount || 0) + (ballotSummary?.invalidBallotCount || 0))
    }

    useEffect(() => {
        if (error || stationError) {
            handleEntityLoadingError(error || stationError)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, stationError])

    useEffect(() => {
        if (!viewMode && info !== undefined) {
            if (station?.stationReport?.comment && !info.comment) {
                setInfo(prevInfo => ({
                    ...prevInfo,
                    comment: station.stationReport.comment
                }))
                form.setFieldsValue({ comment: station.stationReport.comment })
            } else {
                form.setFieldsValue({ comment: info.comment || '' })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [station?.stationReport?.comment, viewMode, info, form])

    useEffect(() => {
        if (stationTellers && stationTellers.length > 0) {
            const allChecked = stationTellers.every(teller => checkedStates[teller.id])
            setIsAllChecked(allChecked)
        }
    }, [stationTellers, checkedStates])

    useEffect(() => {
        if (stationTellers && station?.stationReport?.signed) {
            const initialCheckedStates = stationTellers.reduce<Record<string, boolean>>((acc, teller) => {
                acc[teller.id] = true

                return acc
            }, {})

            setCheckedStates(initialCheckedStates)
            setIsAllChecked(true)

        } else if (stationTellers && !station?.stationReport?.signed) {
            const resetCheckedStates = stationTellers.reduce<Record<string, boolean>>((acc, teller) => {

                acc[teller.id] = false

                return acc
            }, {})

            setCheckedStates(resetCheckedStates)
            setIsAllChecked(false)
        }
    }, [stationTellers, station?.stationReport?.signed])

    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newComment = e.target.value

        if (info !== undefined) {
            setInfo(prevInfo => ({
                ...prevInfo,
                comment: newComment
            }))
        }

        form.setFieldsValue({ comment: newComment })
        setCommentStatus('')
    }

    useImperativeHandle(ref, () => ({
        handleButtonClick: async () => {
            try {
                await form.validateFields()
                setCommentStatus('')
            } catch (errorInfo) {
                if (hasDiscrepancy && !(info.comment ?? '').trim()) {
                    setCommentStatus('error')

                    return
                }
            }

            if (isAllChecked || station?.stationReport?.signed) {
                setValid(false)
                const signaturesData = Array.isArray(stationTellers) ? stationTellers.map(teller => ({
                    teller: teller.id,
                    agreement: checkedStates[teller.id]
                })) : []

                try {
                    await signStationReportMutation({
                        signatures: signaturesData,
                        comment: info.comment
                    })
                    setInfo(current => ({ ...current, completed: true }))
                } catch (e) {
                    handleError(e, showInfo)

                    return
                }
                if (props?.onSigned) {
                    props.onSigned()
                } else {
                    localStorage.removeItem('user')
                }
            } else {
                showNoSignedReportPopup()
                setValid(true)
            }
        }
    }))

    const handleCheckboxChange = (bahaiId: string, checked: boolean) => {
        setCheckedStates({ ...checkedStates, [bahaiId]: checked })
    }

    const showNoSignedReportPopup = () => {
        showInfo({
            title: t('no_signed_report'),
            text:
                <div className={style.content}>
                    {t('to_complete_the_telling_station_process_please_check_the_box')}
                </div>,
            onOk: async () => { }
        })
    }

    return (
        <>
            {infoPortal}
            {props.tutorial}
            <Form form={form}>
                <Card
                    noContentPadding={true}
                    noHeaderLine={true}>
                    <div className={style.contentWrapper}>
                        <reporting.features.reportingAssets.components.ReportLeftCardBlock
                            title={t('tellers_statistics')}
                        >
                            <div className={style.subTitle}>
                                <span>{`${t('teller:telling_station')} 
                            ${(paramsStationId || auth!.stationId)?.replace('S', '')}`}</span>
                            </div>
                            {!props.hideElectionTypeLabel &&
                                <reporting.features.reportingAssets.components.ElectionEndYearBlock
                                    election={election}
                                />
                            }
                            <div id="signature-comment">
                                {!viewMode && hasDiscrepancy
                                    && <Note
                                        mode={'warning'}
                                        icon={'filled'}
                                        title={t('please_explain_the_discrepancy')}>
                                        {t('there_is_a_discrepancy_between_the_number_of_verified_voters',
                                            discrepancyDetails)}
                                    </Note>
                                }
                                <div className={`
                                ${viewMode ? style.commentViewMode : style.comentLabel}
                                ${!viewMode && commentStatus === 'error' ? style.errorCommentLabel : ''}`}>
                                    {t('comment')}
                                </div>
                                {viewMode
                                    ? <div className={style.disabledComment}>
                                        {station?.stationReport.comment
                                            ? station?.stationReport.comment
                                            : String.fromCharCode(8212)}
                                    </div>
                                    : <Form.Item
                                        name={'comment'}
                                        noStyle={true}
                                        rules={[
                                            {
                                                required: hasDiscrepancy
                                            }
                                        ]}
                                    >
                                        <TextArea
                                            value={info?.comment || ''}
                                            onChange={handleCommentChange}
                                            placeholder={t('please_type_comments_including_if_needed')}
                                            showCount={true}
                                            maxLength={1000}
                                            autoSize={{ minRows: 3, maxRows: 8 }}
                                            status={commentStatus}
                                        />
                                    </Form.Item>
                                }
                            </div>
                            <div id="signature-section">
                                <reporting.features.reportingAssets.components.StationInfo />
                                {Array.isArray(tellingStationSignersData) && tellingStationSignersData.map(teller => (
                                    <div key={teller.id} className={style.checkboxBlock}>
                                        <div className={valid && !checkedStates[teller.id]
                                            ? style.checkboxError : ''}>
                                            <Checkbox
                                                disabled={viewMode || station?.stationReport?.signed}
                                                checked={checkedStates[teller.id] || viewMode}
                                                onChange={(e) => handleCheckboxChange(teller.id, e.target.checked)}>
                                                <span className={viewMode || station?.stationReport?.signed
                                                    ? style.disabledCheckbox : (valid && !checkedStates[teller.id])
                                                        ? style.checkboxErrorText : (checkedStates[teller.id]
                                                            ? style.checkedCheckboxText : style.checkboxText)}>
                                                    {/* eslint-disable-next-line max-len */}
                                                    {t('i_name_bahaiid_id_confirm_the_telling_data_entered_by_the_station', {
                                                        name: teller.name,
                                                        interpolation: { escapeValue: false },
                                                        id: teller.id
                                                    })}
                                                </span>
                                            </Checkbox>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </reporting.features.reportingAssets.components.ReportLeftCardBlock>
                        <reporting.features.reportingAssets.components.ReportRightCardBlock>
                            <div id="station-statistic" className={style.stationStatistic}>
                                <reporting.features.reportingAssets.components.TitleStatistic
                                    number={'1.'}
                                    text={t('total_of_all_the_ballots_envelopes_this_station_started_with')}
                                    statistic={voterSummaryReport?.outerEnvelopeCount || 0}
                                />
                                <reporting.features.reportingAssets.components.SubtitleStatistic
                                    number={'1.1'}
                                    text={t('total_of_valid_ballots_entered')}
                                    statistic={ballotSummary?.validBallotCount || 0}
                                />
                                <reporting.features.reportingAssets.components.SubtitleStatistic
                                    number={'1.2'}
                                    text={t('total_of_invalid_ballots')}
                                    statistic={(ballotSummary?.invalidBallotCount || 0) +
                                        (voterSummaryReport?.markedInnerEnvelopeCount || 0)}
                                />
                                <reporting.features.reportingAssets.components.InvalidBallotsStatistic
                                    voterSummaryReport={voterSummaryReport}
                                    ballotSummary={ballotSummary}
                                />
                                <reporting.features.reportingAssets.components.InfoBlock />
                                <reporting.features.reportingAssets.components.TitleStatistic
                                    number={'2.'}
                                    text={t('total_of_verified_eligible_electors')}
                                    statistic={stationVerifiedVoterCount?.totalCount ?? 0}
                                />
                                <reporting.features.reportingAssets.components.SubtitleStatistic
                                    number={'2.1'}
                                    text={t('number_voting_absentee')}
                                    statistic={stationVerifiedVoterCount?.votedInAbsenceCount ?? 0}
                                />
                                <reporting.features.reportingAssets.components.SubtitleStatistic
                                    number={'2.2'}
                                    text={t('number_of_voting_in_person')}
                                    statistic={stationVerifiedVoterCount?.votedInPersonCount ?? 0}
                                />
                                <reporting.features.reportingAssets.components.TitleStatistic
                                    number={'3.'}
                                    text={t('total_of_votes')}
                                    statistic={(voteSummary?.validVoteCount || 0)
                                        + (voteSummary?.invalidVoteCount || 0)}
                                />
                                <reporting.features.reportingAssets.components.SubtitleStatistic
                                    number={'3.1'}
                                    text={t('total_of_valid_votes')}
                                    statistic={(voteSummary?.validVoteCount || 0)}
                                />
                                <reporting.features.reportingAssets.components.SubtitleStatistic
                                    number={'3.2'}
                                    text={t('total_of_invalid_votes')}
                                    statistic={(voteSummary?.invalidVoteCount || 0)}
                                />
                                <reporting.features.reportingAssets.components.InvalidVotesStatistic
                                    voteSummary={voteSummary}
                                />
                            </div>
                        </reporting.features.reportingAssets.components.ReportRightCardBlock>
                    </div>
                </Card >
            </Form>
        </>
    )
})

export default StatisticalSignature