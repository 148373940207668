import { Select as AntSelect, Form } from 'antd'
import { Select } from 'components/atoms/Select'
import { useElectionTypeMap } from 'hooks'
import { ElectionType } from 'models'
import { Key, ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type ElectionTypeFilterProps = {
    disabled?: boolean
    key?: Key | null | undefined
    electionTypesToSelect?: ElectionType[]
}

function ElectionTypeFilter({ disabled, key, electionTypesToSelect }: ElectionTypeFilterProps) {
    const { t } = useTranslation()
    const { toString } = useElectionTypeMap()

    const options = useMemo(() => {
        if (electionTypesToSelect?.length) {
            const elections: ReactNode[] = []
            electionTypesToSelect.map(electionType => elections.push(<AntSelect.Option
                value={electionType}
                key={electionType}>
                {toString(electionType)}
            </AntSelect.Option>))

            return <>{elections}</>
        }

        return <>
            <AntSelect.Option value={ElectionType.RIDVAN_ELECTION}>
                {toString(ElectionType.RIDVAN_ELECTION)}
            </AntSelect.Option>
            <AntSelect.Option value={ElectionType.LOCAL_DELEGATE_ELECTION}>
                {toString(ElectionType.LOCAL_DELEGATE_ELECTION)}
            </AntSelect.Option>
            <AntSelect.Option value={ElectionType.BY_ELECTION}>
                {toString(ElectionType.BY_ELECTION)}
            </AntSelect.Option>
        </>
    }, [electionTypesToSelect])

    return (
        <Form.Item
            key={key}
            name={['electionType']}
            className="no-padding"
            label={t('election:election_type')}
            style={{ width: 300 }}
            required
        >
            <Select
                disabled={disabled}
                placeholder={t('select_input', { input: t('election:election_type') })}
                className="small">
                {options}
            </Select>
        </Form.Item>
    )
}

export default ElectionTypeFilter