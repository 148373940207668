import { useElection, useElectionSummary, useElectionTellingStations, useOnlineVoting } from 'api'
import MilestoneItem from 'components/atoms/MilestoneItem'
import ElectionBadge from 'components/molecules/ElectionBadge'
import { useAbility, useAuth } from 'hooks'
import { ReactComponent as Chevron } from 'icons/chevron.svg'
import { ElectionStatusType, ElectionType, MilestoneColorType } from 'models'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import style from './index.module.scss'

function MergingResultsMilestone() {
    const { t } = useTranslation('teller')
    const navigate = useNavigate()
    const { ability } = useAbility()

    const { electionId } = useParams()
    const { auth } = useAuth()
    const { data: election } = useElection(electionId || auth!.electionId!, !!electionId || !!auth?.electionId)
    const { data: electionResultSummary } = useElectionSummary(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: onlineVoting } = useOnlineVoting(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)
    const { data: tellingStations } = useElectionTellingStations(electionId || auth!.electionId!,
        !!electionId || !!auth?.electionId)

    const [milestoneColor, setMilestoneColor] = useState<MilestoneColorType>(MilestoneColorType.DEFAULT)
    const [milestoneButton, setMilestoneButton] = useState<ReactNode>()
    const [milestoneStatus, setMilestoneStatus] = useState<ElectionStatusType>(ElectionStatusType.NOT_STARTED)

    useEffect(() => {
        if (election) {
            if (election.type !== ElectionType.RBC_ELECTION
                && electionResultSummary?.tiePresent
                && !electionResultSummary?.breakingTieSkipped) {
                setMilestoneColor(MilestoneColorType.PRIMARY)

                return
            }
            if (!election.bypassFlow
                && (!tellingStations?.length || !!tellingStations?.find(s => !s.stationReportAccepted || !s.closed))) {
                setMilestoneColor(MilestoneColorType.DEFAULT)

                return
            }

            if (electionResultSummary?.mergedBy) {
                setMilestoneColor(MilestoneColorType.SUCCESS)

                return
            }

            setMilestoneColor(MilestoneColorType.PRIMARY)
        }
    },
        [
            election,
            electionResultSummary,
            tellingStations
        ])

    useEffect(() => {
        if (election) {
            if (!election.bypassFlow && (!tellingStations?.length
                || !!tellingStations?.find(s => !s.stationReportAccepted || !s.closed))) {
                setMilestoneStatus(ElectionStatusType.NOT_STARTED)

                return
            }

            if (election.type !== ElectionType.RBC_ELECTION
                && electionResultSummary?.tiePresent
                && !electionResultSummary?.breakingTieSkipped) {
                setMilestoneStatus(ElectionStatusType.IN_PROGRESS)

                return
            }

            if (electionResultSummary?.mergedBy) {
                setMilestoneStatus(ElectionStatusType.COMPLETED)

                return
            }

            setMilestoneStatus(ElectionStatusType.NOT_STARTED)

        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            election,
            electionResultSummary,
            tellingStations
        ])

    useEffect(() => {
        if (election) {

            if (election.type !== ElectionType.RBC_ELECTION
                && electionResultSummary?.tiePresent
                && !electionResultSummary?.breakingTieSkipped) {
                if (!ability?.can('view-only', 'ChiefTellerActions')) {
                    setMilestoneButton(<button className="btn-main-primary-md"
                        onClick={() => { navigate(`/merged-results${electionId ? '/' + electionId : ''}`) }}>
                        {t('break_the_tie')}
                    </button>)
                }

                return
            }

            if (ability?.can('view-only', 'ChiefTellerActions')) {
                if (electionResultSummary?.mergedBy) {
                    setMilestoneButton(<button className="btn-main-secondary-md"
                        onClick={() => {
                            navigate(`/merged-results${electionId ? '/' + electionId : ''}`,
                                electionId
                                    ? { state: { backButtonTitle: t('election:back_to_election_board') } }
                                    : undefined)
                        }}
                    >
                        {t('view_results_ballots')}
                    </button>)
                } else {
                    setMilestoneButton(null)
                }

                return
            }

            if (!onlineVoting?.closed && !onlineVoting?.votingReportAccepted) {
                setMilestoneButton(<button className="btn-main-secondary-md" disabled>
                    {t('start_merging')}
                </button>)

                return
            }

            if (electionResultSummary?.mergedBy) {
                setMilestoneButton(<button className="btn-main-secondary-md"
                    onClick={() => {
                        navigate(`/merged-results${electionId ? '/' + electionId : ''}`,
                            electionId
                                ? { state: { backButtonTitle: t('election:back_to_election_board') } }
                                : undefined)
                    }}
                >
                    {t('view_results_ballots')}
                </button>)

                return
            }

            if (election.bypassFlow) {
                setMilestoneButton(<button className="btn-main-primary-md"
                    onClick={() => { navigate('/merge-results') }}>
                    {t('start_merging')}
                </button>)

                return
            }

            if (!tellingStations?.length || !!tellingStations?.find(s => !s.stationReportAccepted || !s.closed)) {
                setMilestoneButton(<button className="btn-main-secondary-md" disabled>
                    {t('start_merging')}
                </button>)

                return
            }

            setMilestoneButton(<button className="btn-main-primary-md"
                onClick={() => { navigate('/merge-results') }}
            >
                {t('start_merging')}
            </button>)

        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            election,
            electionResultSummary,
            tellingStations
        ])

    return (
        <div className={style.contentWrapper}>
            <MilestoneItem
                title={t('merging_results')}
                color={milestoneColor}
                children={milestoneButton}
                status={<ElectionBadge status={milestoneStatus} />}
            />
            <div className={style.arrowIcon}><Chevron /></div>
        </div>
    )
}

export default MergingResultsMilestone