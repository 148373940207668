import { useElectionStations, useElectionsSummary, useOnlineVotingInfos } from 'api'
import BallotingProgressBar from 'components/molecules/BallotingProgressBar'
import { ElectionBallotingProgressStatus, ElectionType, SubElectionViewModel } from 'models'
import { useEffect, useState } from 'react'
import style from './index.module.scss'

type SubElectionBallotingProgressProps = {
    allElectionsIds: string[],
    election: SubElectionViewModel,
    electionType: ElectionType
}

function SubElectionBallotingProgress({
    allElectionsIds,
    election,
    electionType
}: SubElectionBallotingProgressProps) {

    const [status, setStatus]
        = useState<ElectionBallotingProgressStatus>(ElectionBallotingProgressStatus.TellingNotStarted)

    const { data: electionsSummary } = useElectionsSummary({
        electionIds: allElectionsIds
    }, !!allElectionsIds)
    const { data: onlineVotingInfos } = useOnlineVotingInfos({
        electionIds: allElectionsIds
    })
    const { data: electionStations } = useElectionStations(election.id.toString(), true)

    useEffect(() => {
        if (electionsSummary) {

            const electionSummary = electionsSummary.find(i => i.election === election.id)

            if (electionSummary) {
                const signed = !!electionSummary.electionResults.signedBy
                const merged = !!electionSummary.electionResults.mergedBy

                if (signed) {
                    setStatus(ElectionBallotingProgressStatus.ReportingComplete)

                    return
                }

                if (merged) {
                    if (electionSummary.electionResults.tiePresent
                        && !electionSummary.electionResults.breakingTieResolved
                        && electionType !== ElectionType.RBC_ELECTION) {
                        setStatus(ElectionBallotingProgressStatus.MergingInProgress)

                        return
                    }

                    setStatus(ElectionBallotingProgressStatus.MergingComplete)

                    return
                }
            }

            const onlineVotingInfo = onlineVotingInfos?.filter(s => s.election === election.id)
            if (onlineVotingInfo?.filter(i => i.votingReportAccepted).length) {
                setStatus(ElectionBallotingProgressStatus.TellingInProgress)

                return
            }

            setStatus(ElectionBallotingProgressStatus.TellingNotStarted)

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electionsSummary, electionStations, onlineVotingInfos])

    return (
        <div className={style.ballotingProgress}>
            <BallotingProgressBar
                status={status}
                bypassFlowEnabled={!!election?.bypassFlow}
            />
        </div>
    )
}

export default SubElectionBallotingProgress