import { ColumnsType } from 'antd/es/table'
import { useCandidatesOnlineVotes, useElectionCandidates } from 'api'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import Table from 'components/molecules/Table'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useCandidatesPhysicalVotes } from '../../api'
import { PhysicalBallotsFlowParticipant } from '../../model'
import style from './index.module.scss'

function TieSubmitPhysicalBallotsTable() {

    const { t } = useTranslation('teller')
    const { electionId } = useParams()

    const [candidates, setCandidates] = useState<PhysicalBallotsFlowParticipant[]>([])

    const { data: electionCandidates } = useElectionCandidates(electionId || '', {}, !!electionId)
    const { data: candidatesOnlineVotes } = useCandidatesOnlineVotes(electionId || '', !!electionId)
    const { data: candidatesPhysicalVotes } = useCandidatesPhysicalVotes(electionId || '', !!electionId)

    useEffect(() => {
        if (electionCandidates) {
            const allCandidates = electionCandidates.map(c => ({
                ...c,
                onlineVotes: 0,
                physicalVotes: 0
            }))

            if (candidatesOnlineVotes) {
                allCandidates.forEach(candidate => {
                    const onlineVotes = candidatesOnlineVotes.find(v => v.candidate === candidate.id)
                    if (onlineVotes) {
                        candidate.onlineVotes = onlineVotes.voteCount
                    }
                })
            }

            if (candidatesPhysicalVotes) {
                allCandidates.forEach(candidate => {
                    const physicalVotes = candidatesPhysicalVotes.find(v => v.candidate === candidate.id)
                    if (physicalVotes) {
                        candidate.physicalVotes = physicalVotes.voteCount
                    }
                })
            }

            allCandidates.sort((a, b) => {
                const totalVotesA = a.onlineVotes + a.physicalVotes
                const totalVotesB = b.onlineVotes + b.physicalVotes

                if (totalVotesB !== totalVotesA) {
                    return totalVotesB - totalVotesA
                }

                return a.name.localeCompare(b.name)
            })

            setCandidates(allCandidates)
        }
    }, [electionCandidates, candidatesOnlineVotes, candidatesPhysicalVotes])


    const columns: ColumnsType<PhysicalBallotsFlowParticipant> = [
        {
            title: t('recipient'),
            width: 380,
            render: (candidate: PhysicalBallotsFlowParticipant) => (
                <div style={{ width: 492 }}>
                    <OptionalTooltip contentWrapperClassName={'ellipsis'}>
                        {candidate.name}
                    </OptionalTooltip>
                </div>
            )
        },
        {
            title: t('total_votes'),
            width: 80,
            render: (candidate: PhysicalBallotsFlowParticipant) => <div style={{ width: 70 }}>
                {(candidate.onlineVotes || 0) + (candidate.physicalVotes || 0)}
            </div>
        }
    ]


    return (
        <Table
            obsSize="small"
            obsHeaderSize="small"
            headerDividerVisible
            stickyOffset="111px"
            dataSource={candidates}
            columns={columns}
            pagination={false}
            rootClassName={style.table}
            rowKey="id"
        />
    )
}

export default TieSubmitPhysicalBallotsTable