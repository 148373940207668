import { useElectionsOnlineVotersCount, useElectionsParticipantsStatistic, useVerifiedVoterCount } from 'api'
import Tooltip from 'components/atoms/Tooltip'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from './index.module.scss'

type SubElectionVotingStatisticProps = {
    allElectionsIds: string[],
    electionId: string
}

function SubElectionVotingStatistic({
    allElectionsIds,
    electionId
}: SubElectionVotingStatisticProps) {
    const { t } = useTranslation()
    const [totalParticipants, setTotalParticipants] = useState(0)
    const [onlineVoters, setOnlineVoters] = useState(0)
    const [onlineInPersonVoters, setOnlineInPersonVoters] = useState(0)
    const [onlineAbsenteeVoters, setOnlineAbsenteeVoters] = useState(0)
    const [totalVoters, setTotalVoters] = useState(0)

    const { data: participantsStatistic } = useElectionsParticipantsStatistic({ electionIds: allElectionsIds })
    const { data: onlineVotersCount } = useElectionsOnlineVotersCount({ electionIds: allElectionsIds })
    const { data: paperBallotsVotersCount } = useVerifiedVoterCount(electionId)

    useEffect(() => {
        const electionParticipantStatistic = participantsStatistic?.find(s => s.election.toString() === electionId)
        if (electionParticipantStatistic) {
            setTotalParticipants(electionParticipantStatistic.participants.voterCount)
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [participantsStatistic])

    useEffect(() => {
        const electionOnlineVotersCount = onlineVotersCount?.find(s => s.election.toString() === electionId)
        if (electionOnlineVotersCount) {
            setOnlineVoters(electionOnlineVotersCount.voters.totalCount)
            setOnlineInPersonVoters(electionOnlineVotersCount.voters.votedOnlineInPerson)
            setOnlineAbsenteeVoters(electionOnlineVotersCount.voters.votedOnlineInAbsence)
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onlineVotersCount])

    useEffect(() => {
        setTotalVoters(onlineVoters + (paperBallotsVotersCount?.totalCount || 0))
    }, [
        onlineVoters, paperBallotsVotersCount
    ])

    const votingStatistic = <>
        <div>{t('administration:participated_voters')}: {totalVoters}</div>
        <ul className={style.participated}>
            <li>{t('telling-station:in_person')}: {paperBallotsVotersCount?.votedInPersonCount || 0}</li>
            <li>{t('telling-station:absentee')}: {paperBallotsVotersCount?.votedInAbsenceCount || 0}</li>
            <li>{t('online_in_person')}: {onlineInPersonVoters}</li>
            <li>{t('online_absentee')}: {onlineAbsenteeVoters}</li>
        </ul>
        <div>{t('administration:eligible_voters')}: {totalParticipants}</div>
    </>

    return (
        <Tooltip title={votingStatistic}>
            {totalVoters}/{totalParticipants} ({(!totalVoters || !totalParticipants)
                ? 0
                : Math.round(totalVoters / totalParticipants * 100)}%)
        </Tooltip>
    )
}

export default SubElectionVotingStatistic