import { AbilityBuilder } from '@casl/ability'
import { Auth, Role } from 'models'
import { AppAbility } from './ability'

export type ReportsSubject = 'DownloadReportButton'

export function reportsAbility(builder: AbilityBuilder<AppAbility>, auth?: Auth) {
    const { can } = builder

    if (auth && (auth.role === Role.LSA_OFFICER)) {
        can('view', 'DownloadReportButton')
    }
}