import { CheckboxChangeEvent } from 'antd/es/checkbox'
import Checkbox from 'components/atoms/Checkbox'
import OptionalTooltip from 'components/molecules/OptionalTooltip'
import { ElectionResult } from 'models'
import { CSSProperties } from 'react'
import { capitalizeFirstLetter } from 'utils'
import style from './index.module.scss'

type MergedResultItemProps = {
    style?: CSSProperties,
    result: ElectionResult,
    selected: boolean,
    onCandidateSelect: (candidateId: string, isChecked: boolean) => void
    isDesignateElectee?: boolean
}

function TieItems({
    style: itemStyle,
    result,
    onCandidateSelect,
    selected,
    isDesignateElectee
}: MergedResultItemProps) {

    const gender = result.gender ? capitalizeFirstLetter(result.gender) : String.fromCharCode(8212)

    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        onCandidateSelect(result.id, e.target.checked)
    }

    return (
        <div className={style.listItem} style={itemStyle}>
            {isDesignateElectee && <div style={{ width: 47 }} className={style.column}>
                <Checkbox onChange={handleCheckboxChange} checked={selected} />
            </div >}
            <div style={{ width: isDesignateElectee ? 220 : 230 }} className={style.column}>
                <OptionalTooltip contentWrapperClassName="ellipsis">
                    {result.name}
                </OptionalTooltip>
            </div >
            <div style={{ width: isDesignateElectee ? 95 : 100 }} className={style.column}>
                {result.id}
            </div >
            <div style={{ width: isDesignateElectee ? 95 : 100 }} className={style.column}>
                {gender}
            </div >
            <div style={{ width: isDesignateElectee ? 155 : 160 }} className={style.column}>
                <OptionalTooltip contentWrapperClassName="ellipsis">
                    {result.race}
                </OptionalTooltip>
            </div >
            <div style={{ width: isDesignateElectee ? 155 : 160 }} className={style.column}>
                <OptionalTooltip contentWrapperClassName="ellipsis">
                    {result.ethnicity}
                </OptionalTooltip>
            </div >
            <div style={{ width: 70 }} className={style.column}>
                {result.voteCount}
            </div >
        </div >
    )
}

export default TieItems