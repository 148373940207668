
import {
    useBreakingTieRounds,
    useElectedCandidates,
    useElectionResultsTie,
    useElectionSummary,
    useOnlineVoting
} from 'api'
import Empty from 'components/molecules/Empty'
import InternalScrollTableDefaultHeader from 'components/molecules/InternalScrollTableDefaultHeader'
import Preloader from 'components/molecules/Preloader'
import { queryClient } from 'config/query-client'
import {
    Election,
    ElectionResultWithTieInfo,
    ElectionStatusType,
    ElectionType,
    InternalScrollTableDefaultColumn
} from 'models'
import { useEffect, useMemo, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useTranslation } from 'react-i18next'
import { FixedSizeList as List } from 'react-window'
import { isElectionFrozen } from 'utils'
import { useElectionResultsCandidates } from '../../../../api'
import ResultsTableFilter from '../../../../components/ResultsTableFilter'
import { useMergeResultsData } from '../../hooks'
import MergedResultItem from '../MergedResultItem'
import style from './index.module.scss'

const LIST_ITEM_HEIGHT = 40
const SHOW_ALL_VACANCIES = '1'
const SHOW_TOP_30 = '2'

type MergedResultsTableProps = {
    election: Election
    showAllVacanciesOnly?: boolean
    showLinkToConfirmResults?: boolean
}

function MergedResultsTable({
    election,
    showAllVacanciesOnly,
    showLinkToConfirmResults
}: MergedResultsTableProps) {
    const { t } = useTranslation('teller')
    const [shownResultsCountOption, setShownResultsCountOption] = useState(SHOW_ALL_VACANCIES)
    const [mergedResults, setMergedResults] = useState<ElectionResultWithTieInfo[]>([])
    const [allResults, setAllResults] = useState<ElectionResultWithTieInfo[]>([])
    const { mergedResultsData, setMergedResultsData } = useMergeResultsData()
    const numberOfVacancies = election?.numberOfVacancies ?? 0

    const { data: electionResultTie } = useElectionResultsTie(election?.id.toString() || '', !!election)
    const { data: onlineVoting } =
        useOnlineVoting(mergedResultsData.lastRound?.id.toString() || '', !!mergedResultsData.lastRound?.id.toString())
    const { data: electionVotingReport } =
        useElectionResultsCandidates(election?.id.toString() || '', {},
            isElectionFrozen(election?.status), !!election?.id)
    const { data: electedCandidates } =
        useElectedCandidates(election?.id.toString() || '', isElectionFrozen(election?.status), !!election?.id)
    const { data: breakingTieRounds } = useBreakingTieRounds(election?.id.toString() || '', !!election?.id)
    const { data: summary } = useElectionSummary(election?.id.toString() || '', !!election?.id)

    const lastTieIndex = allResults.reduceRight((acc, result, index) => {
        if (acc !== -1) {
            return acc
        }

        return result.isTie ? index : -1
    }, -1)

    const tableMaxHeight = useMemo(() => {
        const heightOfElectedAndInTie = allResults.slice(0, lastTieIndex + 1).length * LIST_ITEM_HEIGHT
        const fitMergedResults = mergedResults.length * LIST_ITEM_HEIGHT
        const defaultMaxHeight = 400

        switch (shownResultsCountOption) {
            case SHOW_ALL_VACANCIES:
                if (mergedResults.length < 10) {
                    return fitMergedResults
                }

                return defaultMaxHeight
            case SHOW_TOP_30:
                if (summary?.tiePresent) {
                    return Math.max(heightOfElectedAndInTie, defaultMaxHeight)
                }

                if (mergedResults.length < 10) {
                    return fitMergedResults
                }

                if (mergedResults.length >= 10) {
                    return defaultMaxHeight
                }
                break

            default:
                if (summary?.tiePresent) {
                    return Math.max(heightOfElectedAndInTie, defaultMaxHeight)
                }

                if (mergedResults.length < 10) {
                    return fitMergedResults
                }

                return defaultMaxHeight
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mergedResults, shownResultsCountOption, summary?.tiePresent, allResults, lastTieIndex])

    const completedRounds = useMemo(() => {
        if (!breakingTieRounds?.length) {
            return []
        }

        return breakingTieRounds.filter(round =>
            round.status === ElectionStatusType.COMPLETED)
    }, [breakingTieRounds])

    const lastCompletedRound = useMemo(() => (completedRounds?.pop()), [completedRounds])

    const { data: tieElectionVotingReport } =
        useElectionResultsCandidates(lastCompletedRound?.id.toString() || '',
            {}, true, !!lastCompletedRound?.id.toString())

    useEffect(() => {
        if (breakingTieRounds) {
            setMergedResultsData(current => ({
                ...current, isAnyBreakingTieRound: breakingTieRounds?.some(round =>
                    round.status !== ElectionStatusType.CANCELED)
            }))
            if (breakingTieRounds.length > 0) {
                setMergedResultsData(current =>
                    ({ ...current, lastRound: breakingTieRounds[breakingTieRounds.length - 1] }))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakingTieRounds])

    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval> | undefined

        if (mergedResultsData.waitingForOnlineVotingStart) {
            intervalId = setInterval(async () => {
                await queryClient.invalidateQueries(['online-voting', mergedResultsData.lastRound?.id.toString()])
                if (onlineVoting) {
                    clearInterval(intervalId)
                    setMergedResultsData(current => ({
                        ...current, waitingForOnlineVotingStart: false
                    }))
                }
            }, 1000)
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mergedResultsData.waitingForOnlineVotingStart, onlineVoting])

    const columns: InternalScrollTableDefaultColumn[] = useMemo(() => {
        const baseColumns = [
            { title: t('pos') + '.', width: 60 },
            { title: t('bahai_id'), width: 105 },
            { title: t('full_name'), width: lastCompletedRound ? 250 : 300 },
            { title: t('gender'), width: 80 },
            { title: t('locality_code'), width: 90 },
            { title: t('locality_name'), width: 160 },
            { title: t('race'), width: lastCompletedRound ? 200 : 230 },
            { title: t('ethnicity'), width: lastCompletedRound ? 200 : 230 },
            { title: t('votes'), width: 80 }
        ]

        if (lastCompletedRound) {
            baseColumns.push({
                title: t('latest_round', { number: lastCompletedRound?.roundNumber.toString() }),
                width: 100
            })
        }

        return baseColumns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastCompletedRound, onlineVoting?.closed])

    const handleResultsCountOptionChange = (value: string) => {
        setShownResultsCountOption(value)
    }

    useEffect(() => {
        setMergedResultsData(current => ({ ...current, electionResultTie }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electionResultTie])

    useEffect(() => {
        if (!electionVotingReport) return

        const tieCandidatesMap =
            new Map(electionResultTie?.candidates.map(candidate => [candidate.id, candidate]))
        const electedCandidatesMap = new Map(electedCandidates?.map(candidate => [candidate.id, candidate]))
        const afterTieEleteesIds = new Set(electionResultTie?.resolution.candidates)
        const afterTieEletees = Array.from(tieCandidatesMap.values())
            .filter(candidate => afterTieEleteesIds.has(candidate.id))
        const voteCountingMap = new Map(tieElectionVotingReport?.map(item => [item.id, item.voteCount]))

        const afterTieEleteesNames = afterTieEletees.map(candidate =>
            candidate.name).filter(name => !!name)
        setMergedResultsData(current => ({
            ...current,
            resolvedCandidatesName: afterTieEleteesNames
        }))

        const electedResults = electedCandidates?.map(candidate => ({
            ...tieCandidatesMap.get(candidate.id) ?? candidate,
            isTie: false,
            isElecteeAfterTie: afterTieEleteesIds.has(candidate.id),
            tieLatestRoundVoteCount: voteCountingMap?.get(candidate.id)
        })) ?? []

        let tieResults: ElectionResultWithTieInfo[] = []
        if (summary?.tiePresent && electionResultTie?.candidates) {
            tieResults = electionResultTie.candidates
                .filter(candidate => !electedCandidatesMap.has(candidate.id) && !afterTieEleteesIds.has(candidate.id))
                .map(candidate => ({
                    ...candidate,
                    isTie: true,
                    tieLatestRoundVoteCount: voteCountingMap?.get(candidate.id)
                }))
        }

        const otherResults = electionVotingReport.filter(candidate =>
            !electedCandidatesMap.has(candidate.id) && !afterTieEleteesIds.has(candidate.id) &&
            (!summary?.tiePresent || !tieCandidatesMap.has(candidate.id))).map(candidate => ({
                ...candidate,
                isTie: false,
                tieLatestRoundVoteCount: voteCountingMap?.get(candidate.id)
            }))

        const sortResults = (results: ElectionResultWithTieInfo[]) => results.sort((a, b) => {

            if (b.voteCount !== a.voteCount) {
                return b.voteCount - a.voteCount
            }

            return a.name?.localeCompare(b.name)
        })

        const sortedElectedResults = sortResults([...electedResults])
        const sortedTieResults = sortResults([...tieResults])
        const sortedOtherResults = sortResults([...otherResults])

        const allResults = [
            ...sortedElectedResults,
            ...sortedTieResults,
            ...sortedOtherResults
        ]

        setAllResults(allResults)

        if (electionResultTie?.resolution.resolved) {
            setMergedResultsData(current => ({
                ...current,

                isTieResolved: electedCandidates?.length
                    === numberOfVacancies && electionResultTie.resolution.resolved && !summary?.tiePresent

            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electionVotingReport, electedCandidates,
        electionResultTie, numberOfVacancies, summary?.tiePresent,
        tieElectionVotingReport, setMergedResultsData])

    useEffect(() => {
        switch (shownResultsCountOption) {
            case SHOW_ALL_VACANCIES:
                const isTiePresent = summary?.tiePresent
                if (isTiePresent) {
                    setMergedResults(allResults.slice(0, lastTieIndex + 1))
                } else {
                    setMergedResults(allResults.slice(0, numberOfVacancies))
                }

                return
            case SHOW_TOP_30:
                setMergedResults(allResults.slice(0, 30))

                return
            default:
                setMergedResults(allResults)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summary, allResults, shownResultsCountOption, tieElectionVotingReport])

    return (
        <>
            {mergedResultsData.waitingForOnlineVotingStart && <Preloader title={t('working_on_your_round')} />}
            <div className={style.listContainer}>
                <ResultsTableFilter
                    summary={summary}
                    title={
                        (election?.type === ElectionType.LOCAL_DELEGATE_ELECTION)
                            ? `${t('merged_results')} (${election.region.localUnit})`
                            : (election?.type === ElectionType.NATIONAL_DELEGATE_ELECTION)
                                ? `${t('merged_results')} (${election.region.unit})`
                                : (election?.type === ElectionType.RBC_ELECTION)
                                    ? `${t('merged_results')} (${election.region.rbc})`
                                    : t('merged_results')
                    }
                    election={election}
                    onFilterChange={handleResultsCountOptionChange}
                    showAllVacanciesOnly={showAllVacanciesOnly}
                    showLinkToConfirmResults={showLinkToConfirmResults}
                />
                <InternalScrollTableDefaultHeader columns={columns} />
                {mergedResults?.length ?
                    <List
                        style={{
                            ...style,
                            maxHeight: tableMaxHeight
                        }}
                        outerElementType={Scrollbars}
                        className={style.list}
                        height={(mergedResults.length ?? 0) * LIST_ITEM_HEIGHT}
                        itemCount={mergedResults.length}
                        itemSize={LIST_ITEM_HEIGHT}
                        width={'auto'}
                    >
                        {({ index, style: itemStyle }) => (
                            <MergedResultItem
                                lastCompletedRound={lastCompletedRound}
                                onlineVotingClosed={onlineVoting?.closed}
                                isTiePresent={summary?.tiePresent}
                                style={itemStyle}
                                index={index}
                                result={mergedResults[index]}
                            />
                        )}
                    </List>
                    : <div className={style.empty}>
                        <Empty />
                    </div>
                }
            </div>
        </>
    )
}

export default MergedResultsTable